Ext.define('FieldServices.view.company.tool.CompanyToolsViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.CompanyToolsViewModel',
    data: {

    },
    stores: {

    },

    formulas: {


    },
});
