Ext.define('FieldServices.view.company.purchases.subscriptions.details.DetailsView', {
    extend: 'Ext.form.Panel',

    requires: [
        'FieldServices.view.company.purchases.subscriptions.details.DetailsViewController',
        'Shared.Components.field.dateTimeField.DateTimeField',
    ],

    xtype: 'subscriptionsDetailsView',

    controller: 'subscriptionsDetailsViewController',

    closeAction: 'hide',

    keyMap: {
        ESC: {
            handler: 'onCloseSubscriptionsDetailsPanel',
            scope: 'controller',
            event: 'keydown',
        },
    },

    title: 'Subscription details',

    tools: [{
        type: 'close',
        handler: 'onCloseSubscriptionsDetailsPanel',
    }],

    scrollable: true,

    layout: 'vbox',

    items: [
        {
            xtype: 'tabpanel',
            itemId: 'subscriptionContent',

            flex: 1,
            tabBar: {
                flex: 1,
                bodyCls: 'scrollableTabbar',
                scrollable: 'x',
            },
            defaults: {
                layout: 'fit',
                flex: 1,
            },
            items: [{
                title: 'Info',
                layout: 'vbox',
                xtype: 'container',
                scrollable: true,
                items: [
                    {
                        xtype: 'container',
                        itemId: 'subscriptionData',
                        html: '',
                    },
                    {
                        xtype: 'container',
                        itemId: 'subscriptionInfo',
                        html: '{}',
                    },
                ],
            }, {

                tab: {
                    title: 'Delete / Restore',
                },

                items: [{
                    xtype: 'formpanel',
                    itemId: 'deleteRestoreForm',
                    cls: 'formPanel_padding',
                    items: [{
                        xtype: 'textfield',
                        name: 'note',
                        label: 'Note: ',
                        reference: 'subscriptionDeleteRestoreNote',
                        isRequired: true,
                    }, {
                      xtype: 'button',
                      itemId: 'submitDeleteRestoreForm',
                      text: 'Delete Subscription',
                      cls: 'btn btn--centered',
                      bind: {
                        text: '{isdeleted ? "Restore" : "Delete"}',
                        disabled: '{!subscriptionDeleteRestoreNote.value}',
                      }
                    },],
                }],
            }, {
                tab: {
                    title: 'Expiry',
                    bind: {
                        hidden: '{isdeleted}',
                    },
                },
                items: [{
                    xtype: 'formpanel',
                    itemId: 'expiresDateForm',
                    cls: 'formPanel_padding',
                    items: [{
                        xtype: 'powerednow.datetimefield',
                        itemId: 'dateTimeField',
                        label: 'Expires date:',
                        labelAlign: 'top',
                        name: 'new_expires_date',
                        mode: 'date',
                        value: new Date(),
                    }, {
                        xtype: 'textfield',
                        name: 'note',
                        label: 'Note: ',
                        isRequired: true,
                    }, {
                        xtype: 'button',
                        itemId: 'submitExpiresDateButton',
                        text: 'Submit',
                        iconCls: 'ion-ios-checkmark-outline',
                        cls: 'btn btn--centered',
                    }],
                }],
            }, {
                title: 'Refund',
                items: [{
                    xtype: 'formpanel',
                    itemId: 'refundForm',
                    cls: 'formPanel_padding',
                    items: [{
                        xtype: 'textfield',
                        name: 'amount',
                        label: 'Amount: ',
                        isRequired: true,
                    }, {
                        xtype: 'textfield',
                        name: 'note',
                        label: 'Note: ',
                        isRequired: true,
                    }, {
                        xtype: 'button',
                        itemId: 'submitRefundButton',
                        text: 'Submit',
                        iconCls: 'ion-ios-checkmark-outline',
                        cls: 'btn btn--centered',
                    }],
                }],
            }, {
                title: 'History',
                items: [{
                    xtype: 'grid',
                    itemId: 'eventHistoryList',

                    plugins: [{
                        type: 'gridcolumnresizing',
                    }],

                    flex: 1,

                    emptyText: 'empty',

                    columns: [{
                        text: 'companyId',
                        dataIndex: 'company_id',
                    }, {
                        text: 'userId',
                        dataIndex: 'user_id',
                    }, {
                        text: 'eventType',
                        dataIndex: 'event_type',
                        renderer: 'renderEventType',
                    }, {
                        text: 'linkedCompanyId',
                        dataIndex: 'linked_company_id',
                    }, {
                        text: 'linkedId',
                        dataIndex: 'linked_id',
                    }, {
                        text: 'dtCreated',
                        dataIndex: 'dt_created',
                    }, {
                        text: 'note',
                        dataIndex: 'note',
                    }, {
                        text: 'details',
                        dataIndex: 'details',
                        flex: 1,
                    }],

                }],
            }],
        }, {
            xtype: 'toolbar',
            docked: 'bottom',
            items: ['->', {
                xtype: 'button',
                itemId: 'renewButton',
                text: 'Force app store/play store renewal check',
                iconCls: 'ion-ios-refresh-outline',
                cls: 'btn',
            }, {
                xtype: 'button',
                itemId: 'infoButton',
                text: 'Load Info',
                iconCls: 'ion-ios-information-outline',
                cls: 'btn',
            }, {
                xtype: 'button',
                itemId: 'closeButton',
                text: 'Cancel',
                iconCls: 'ion-ios-close-outline',
            }],
        },
    ],
});
