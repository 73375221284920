/* eslint-disable padded-blocks */
import { getKeyByValue } from '@powerednow/shared/modules/utilities/object';
import { SUBSCRIPTION } from '@powerednow/shared/constants';

const dateUtils = require('shared/modules/utilities/date');
const moment = require('moment');

Ext.define('FieldServices.model.PurchasesInfo', {
    extend: 'Ext.data.Model',

    requires: [
        'Ext.data.proxy.Rest',
    ],

    idProperty: 'clientId',

    fields: [
        { name: 'product' },
        { name: 'purchase_currency' },
        { name: 'expires_date' },
        { name: 'purchase_date' },
        { name: 'purchase_origin' },
        { name: 'purchase_amount' },
        { name: 'quantity' },
        {
            name: 'product_name',
            calculate: data => {
                const purchaseSetup = Ext.getStore('PurchaseSetup');
                const index = purchaseSetup.findBy(record => record.get('ios_product_id') === data.product || record.get('android_product_id') === data.product);
                const purchaseSetupRecord = purchaseSetup.data.items[index];
                return purchaseSetupRecord && purchaseSetupRecord.getData && purchaseSetupRecord.getData().product_name;
            },
        },
        {
            name: 'purchase_setup_id',
            calculate: data => {
                const purchaseSetup = Ext.getStore('PurchaseSetup');
                const currentPurchaseSetup = purchaseSetup.data.items.find(item => item.data.ios_product_id === data.product || item.data.android_product_id === data.product);
                return currentPurchaseSetup?.data?.id;
            },
        },
        {
            name: 'expiresDateView',
            calculate: data => shared.Utils.Renderers.getDateRenderer('Y-m-d')(data.expires_date),
        }, {
            name: 'purchaseDateView',
            calculate: data => shared.Utils.Renderers.getDateRenderer('Y-m-d')(data.purchase_date),
        }, {
            name: 'purchaseOriginName',
            calculate: data => getKeyByValue(SUBSCRIPTION.PURCHASE_ORIGIN_ID, parseInt(data.purchase_origin, 10)),
        }, {
            name: 'duration',
            calculate: data => {
                const expiry = moment(data.expires_date);
                const purchase = moment(data.purchase_date);
                return expiry.from(purchase).replace('in', '');
            },
        }, {
            name: 'weight',
            calculate: data => {
                const purchaseSetup = Ext.getStore('PurchaseSetup');
                const index = purchaseSetup.findBy(record => record.get('ios_product_id') === data.product) || purchaseSetup.findBy(record => record.get('android_product_id') === data.product);
                const purchaseSetupRecord = purchaseSetup.data.items[index];
                return purchaseSetupRecord && purchaseSetupRecord.data.weight;
            },
        }, {
            name: 'purchaseAmount',
            calculate: data => {
                const amount = parseInt(data.purchase_amount, 10);
                return Number.isNaN(amount) ? 0 : amount;
            },
        }, {
            name: 'isActive',
            calculate: data => {
                const date = new Date();
                const startDate = data.purchase_date;
                const endDate = data.expires_date;
                return dateUtils.isDateBetween({ date, startDate, endDate });
            },
        },
    ],
});
