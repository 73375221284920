import './tabs/General';
import './tabs/PricedItems';

export default Ext.define('FieldServices.view.company.job.edit.JobEditView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.company.job.edit.JobEditViewController',
        'FieldServices.view.company.job.edit.JobEditViewModel',
    ],
    layout: 'fit',

    xtype: 'jobEditView',

    controller: 'jobEditViewController',

    store: 'JobItemGroup',

    viewModel: 'jobEditViewModel',

    listeners: {
        painted: 'onLoad',
    },

    scrollable: true,
    nameHolder: true,

    title: 'Edit Job',

    tools: [{
        type: 'close',
        handler: 'onCancelButtonTap',
    }],

    cls: 'panel_padding',

    changeDocumentDataFields: ['id', 'displaynumber', 'documentnumber'],
    items: [{
        xtype: 'tabpanel',
        items: [{
            xtype: 'JobEditGeneralTab',
            title: 'General',
        }, {
            xtype: 'JobEditPricedItemsTab',
            title: 'Priced items',
        }],
    }],
});
