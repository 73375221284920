import { MESSAGES } from '@powerednow/shared/constants';

export default Ext.define('FieldServices.view.company.tools.messageLimitStats.MessageLimitStatsViewController', {
  extend: 'Ext.app.ViewController',
  alias: 'controller.MessageLimitStatsViewController',
  control: {
    view: {
      show: 'loadMessageStats',
    },
    refreshLimitStatsBtn: {
      tap: 'loadMessageStats',
    },
    statsContainer: true,
  },

  messageTypes: {
    [MESSAGES.TYPES.SMS]: 'sms',
    [MESSAGES.TYPES.EMAIL]: 'email',
    [MESSAGES.TYPES.EMAIL_CC]: 'email',
    [MESSAGES.TYPES.HOME]: 'sms',
    [MESSAGES.TYPES.WORK]: 'sms',
    [MESSAGES.TYPES.PORTAL]: 'email',
  },

  onCancelButtonTap() {
    this.getView().close();
  },

  async loadMessageStats() {
    this.getRefreshLimitStatsBtn().setHidden(true);
    const view = this.getView();
    this.getStatsContainer().setItems([]);
    view.setMasked({ xtype: 'loadmask' });
    try{
      const limitStats = await FieldServices.app.callAPI({
        method: 'POST',
        url: `api/admindata/company/getMessageLimitStats/${this.getViewModel().get('companyId')}`,
        jsonData: {}, //we can introduce a date input if needed, but I will leave that out for now
      });
      const newStatItems = [];
      Object.values(limitStats).forEach(limitCategory => {
        const { label, limit, sent, isFromAdmin } = limitCategory;
        const limitText = limit === -1 ? 'Unlimited' : limit
        const statItemHtml =
          `<h2>${label}</h2>
         <ul>
            <li>limit: ${limitText} ${isFromAdmin ? '(Manually set from admin)' : ''}</li>
            <li>sent: ${sent} ${limit !== -1 && sent >= limit ? '<b style="color: darkred">Limit reached!</b>' : ''}</li>
         </ul>`;
        newStatItems.push(Ext.create({
          xtype: 'container',
          html: statItemHtml
        }));
      })
      this.getStatsContainer().setItems(newStatItems);
    } catch(e) {
      this.getStatsContainer().setItems(Ext.create({xtype: 'container', html: '<h3>Message Limit Overview loading failed.</h3>'}))
    }

    view.setMasked(false);
    this.getRefreshLimitStatsBtn().setHidden(false);
  }

});
