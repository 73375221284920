import { ADMIN_AUDIT_TRAIL } from '@powerednow/shared/constants';
Ext.define('FieldServices.view.company.purchases.extra.ExtraViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.extraViewController',
    xtype: 'extraViewController',

    mixins: ['FieldServices.mixins.subPage'],

    control: {
        '': {
            setSubPage: 'setSubPage',
            painted: 'onViewShow',
        },
        addExtraButton: {
            tap: 'onAddExtraButtonTap',
        },
        printExtraButton: {
            tap: 'onPrintExtraButton',
        },
        resetConsumptionsButton: {
            tap: 'onResetConsumptionsButtonTap',
        },
    },

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this.reloadExtrasList, this);
    },

    onViewShow() {
        this.reloadExtrasList().catch(handleClientError);
    },

    onAddExtraButtonTap() {
        const popup = References.getExtraPopup();
        popup.show().on('onReload', () => {
            Ext.getStore('ConsumptionExtra').load();
            this.reloadExtrasList().catch(handleClientError);
        }, this, {
            single: true,
        });
        popup.getController().setCompanyId(this.getViewModel().get('companyId'));
    },

    async onResetConsumptionsButtonTap() {
        await FieldServices.app.callAPIEndpointRequest('POST', `api/admindata/fixConsumptionIds/${this.getViewModel().get('companyId')}`);
        await this.reloadExtrasList().catch(handleClientError);
    },

    async reloadExtrasList() {
        await this.showLoader(this.getView());
        await this.getGridFilterPlugin().reloadData();
        await this.clearLoader(this.getView());
    },

    getGridFilterPlugin() {
        return this.getView().getPlugin('extraFilterPlugin');
    },

    onPrintExtraButton() {
        this.getView().saveDocumentAs({
            fileName: 'extrasList.xlsx',
        });
    },

  async onDeleteRestoreBtnTap(elem) {
    const data = elem.ownerCmp.getRecord().getData();
    const apiMethod = data.isdeleted ? 'PUT' : 'DELETE';
    const recordData = data.isdeleted ? {...data, isdeleted: 0} : data;
    const detailsData = {
      id: data.id,
      company_id: data.company_id,
    };
    const event = data.isdeleted ? ADMIN_AUDIT_TRAIL.EVENT_TYPES.RESTORE_CONSUMPTION : ADMIN_AUDIT_TRAIL.EVENT_TYPES.DELETE_CONSUMPTION;
    this.showDeleteRestoreDialog(data.isdeleted, {apiMethod, recordData, detailsData, event})

  },

  showDeleteRestoreDialog(isRestore, handlerConfig){
    const actionText = isRestore ?  'Restore' : 'Delete';
    const dialog = Ext.create({
      xtype: 'dialog',
      title: `${actionText} Extra`,
      viewModel: true,

      items: [
        {
          xtype: 'formpanel',
          itemId: 'deleteRestoreForm',
          cls: 'formPanel_padding',
          items: [{
            xtype: 'textfield',
            name: 'note',
            label: 'Note: ',
            reference: 'consumptionDeleteRestoreNote',
            isRequired: true,
          },],
        }
      ],

      buttons: {
        ok: {
          text: actionText,
          handler: (buttonEl) => {
            const { consumptionDeleteRestoreNote } = buttonEl.getParent().getParent().getViewModel().getData();
            this.performDeleteRestore(handlerConfig, consumptionDeleteRestoreNote.value).catch(handleClientError);
            dialog.destroy();
          },
          ui: '-confirm',
          bind: {
            disabled: '{!consumptionDeleteRestoreNote.value}',
          }
        },
        cancel: {
          text: 'Cancel',
          ui: '-danger',
          handler:  () => { dialog.destroy() }
        }
      }
    });

    dialog.show();
  },

  async performDeleteRestore({apiMethod, recordData, detailsData, event}, actionNote) {
    await this.showLoader(this.getView());
    await FieldServices.app.callAPIEndpointRequest(apiMethod, `api/admindata/company-tables/ConsumptionExtra/${this.getViewModel().get('companyId')}`, recordData);
    this._sendEvent(event,recordData,detailsData, actionNote);
    await this.getGridFilterPlugin().reloadData();
    await this.clearLoader(this.getView());
  },

  _sendEvent(eventType, consumptionData, detailsData, actionNote) {
    return FieldServices.app.callAPI({
      method: 'POST',
      url: 'api/admindata/eventLog/add',
      jsonData: {
        event_type: eventType,
        linked_company_id: consumptionData.company_id,
        linked_id: consumptionData.id,
        linked_table_name: 'ConsumptionExtra',
        note: actionNote,
        details: {
          data: detailsData,
        },
      },
    });
  },

});
