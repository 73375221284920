import API_RESPONSES from '@powerednow/shared/constants/apiResponses';

/**
 * Created by steveetm1 on 30/07/15.
 */
Ext.define('FieldServices.view.login.LoginController', {
    extend: 'FieldServices.view.BaseViewController',
    requires: ['FieldServices.view.home.HomeView'],
    alias: 'controller.view.login',
    config: {
        control: {
            '#loginButton': {
                release: 'onLoginClick',
                click: 'onLoginClick',

            },
            '#confirmButton': {
                click: 'onConfirmButton',
                release: 'onConfirmButton',
            },
            '#backButton': {
                click: 'onBackButton',
                release: 'onBackButton',
            },
        },
        routes: {
            'show/Home/': 'showDashboard',
        },
    },

    init() {
        this.getView().down('#adminAppVersion').setHtml(`Admin build version: <b>${Utilities.getVersion()}</b><br/>&nbsp;`);
    },

    onBackButton() {
        const codeField = this.lookupReference('codeField');
        if (codeField) {
            codeField.setValue('');
        }
        this.hide2FAFields();
    },

    showDashboard() {
        References.getMainContainer().setActiveItem(References.getDashboard().show());
    },

    onLoginClick() {
        this._login();
    },

    onEnterKey() {
        var activeCardIndex = this.getView().down('#loginFieldsets').getActiveItem().getItemId();

        if (activeCardIndex === 'initialLoginCard') {
            this._login();
        } else if (activeCardIndex === 'twoFACard') { 
            this.onConfirmButton();
        }
    },

    async onLoginSuccess(response) {
        this.response = response;

        const storage = Ext.util.LocalStorage.get('auth');
        if (response && response.status === 200) {
            const responseObj = Ext.decode(response.responseText);
  
            if (responseObj && responseObj.userData && responseObj.token) {
                const adminToken = responseObj.token;
                storage.setItem('adminToken', adminToken);
                storage.setItem('userData', Ext.encode(responseObj.userData));
                storage.save();
                storage.release();
                FieldServices.app.addHeaderToProxies();
                await AuthManager.loadAdminRoles();
                Ext.getStore('WhiteLabel').load();
                this.getView().setKeyMap(null);
                this._goToHomeScreen();
            } else {
                storage.clear();
                Ext.Msg.alert('Login error', 'Invalid user name or password!');
            }
        } else {
            storage.clear();
            Ext.Msg.alert('Login error', 'Unknown error');
        }

        await this.clearLoader(this.getView());
    },

    async onLoginFailure(request) {
        let errMsg = 'Server error';
        let headerTxt = 'Login error';

        if (request.responseText) {
            const respText = Ext.decode(request.responseText);
            if (respText.error_msg) {
                errMsg = respText.error_msg;
            }

            if (respText.error_code === API_RESPONSES.TWO_FA_AUTH_REQUIRED.code) {
                this.show2FAFields();
                await this.clearLoader(this.getView());
                return;
            }

            if (respText.error_code === API_RESPONSES.TOO_MANY_ATTEMPTS.code) {
                this.hide2FAFields();
            }
        }

        Ext.Msg.alert(headerTxt, errMsg);

        await this.clearLoader(this.getView());
    },

    _goToHomeScreen() {
        FieldServices.app.goToHome();
    },

    async _login() {
        const storage = Ext.util.LocalStorage.get('auth');
        const adminToken = storage.getItem('adminToken');
        storage.release();

        if (adminToken) {
            FieldServices.app.addHeaderToProxies();
            this._goToHomeScreen();
            return;
        }

        const button = this.getView().down('#loginButton');
        const form = button.up('formpanel');
        const email = form.down('#email').getValue();
        const password = form.down('#password').getValue();

        if (!email || !password) {
            return;
        }

        await this.showLoader(this.getView());

        const digest = shared.Utils.utilities.hash(password);

        this.passwordHash = digest.toString();
        this.email = email;

        Ext.Ajax.request({
            url: `${require('config.js').SERVER_URL}api/interfaces/adminlogin`,
            method: 'POST',
            defaultPostHeader: 'application/json',
            params: Ext.JSON.encode({
                user: this.email,
                password: this.passwordHash,
            }),
            success: Ext.bind(this.onLoginSuccess, this),
            failure: Ext.bind(this.onLoginFailure, this),
        });
    },

    async onConfirmButton() {
        const code = this.lookupReference('codeField').getValue();

        if (!code) {
            return;
        }

        await this.showLoader(this.getView());

        Ext.Ajax.request({
            url: `${require('config.js').SERVER_URL}api/interfaces/adminlogin`,
            method: 'POST',
            defaultPostHeader: 'application/json',
            params: Ext.JSON.encode({
                user: this.email,
                password: this.passwordHash,
                twoFactorCode: code,
            }),
            success: Ext.bind(this.onLoginSuccess, this),
            failure: Ext.bind(this.onLoginFailure, this),
        });
    },

    show2FAFields() {
        this.getView().down('#loginFieldsets').setActiveItem(1); // Switch to the 2FA card;
    },

    hide2FAFields() {
        this.getView().down('#loginFieldsets').setActiveItem(0);
    }
});
