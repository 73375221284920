import ComplexModel from 'shared/modules/complexData/productTierToFeature';

export default Ext.define('FieldServices.model.ProductTierToFeature', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'id',

});
