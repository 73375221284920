const CONFIG = require('config.js');

Ext.define('FieldServices.view.company.job.edit.JobEditViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.jobEditViewController',
    xtype: 'jobEditViewController',

    config: {
        companyId: null,
        documentData: null,
    },

    onLoad() {
        this._load();
    },

    onCancelButtonTap() {
        this._hide();
    },

    _load() {
        this.setDocumentData(this.getView().getViewModel().getData());
    },

    _hide() {
        this.getView().hide();
    },
});
