export default Ext.define('FieldServices.view.company.document.edit.DocumentEditView.tabs.General', {
    extend: 'Ext.Container',
    alias: 'widget.DocumentEditGeneralTab',
    layout: 'vbox',
    defaultType: 'textfield',

    items: [
        {
            bind: {
                value: '{id}',
            },
            label: 'id',
            labelAlign: 'top',
            name: 'id',
            disabled: true,
        }, {
            bind: {
                value: '{getDisplayNumber}',
                disabled: '{!isSupplierInvoice}',
            },
            label: 'display number (prefix, document number, suffix)',
            labelAlign: 'top',
            name: 'displaynumber',
        }, {
            bind: {
                value: '{prefix}',
            },
            label: 'prefix',
            labelAlign: 'top',
            name: 'prefix',
            disabled: true,
        }, {
            itemId: 'documentnumber',
            bind: {
                value: '{documentnumber}',
            },
            label: 'document number',
            name: 'documentnumber',
            labelAlign: 'top',
            convertFn: value => parseInt(value, 10),
        }, {
            bind: {
                value: '{suffix}',
            },
            label: 'suffix',
            labelAlign: 'top',
            name: 'suffix',
            disabled: true,
        },

        {
            xtype: 'toolbar',
            docked: 'bottom',
            items: ['->',
                {
                    xtype: 'button',
                    text: 'View Online',
                    iconCls: 'ion-ios-eye-outline',
                    handler: 'onViewOnlineButtonTap',
                    // bind: {
                    //     hidden: '{isdeleted}',
                    // },
                },
                {
                    xtype: 'button',
                    text: 'Delete',
                    iconCls: 'ion-ios-trash-outline',
                    handler: 'onDeleteDocumentButtonTap',
                    bind: {
                        hidden: '{isdeleted}',
                    },
                },
                {
                    xtype: 'button',
                    text: 'Restore',
                    iconCls: 'ion-ios-undo',
                    handler: 'onRestoreDocumentButtonTap',
                    bind: {
                        hidden: '{!isdeleted || !confirmed}',
                        disabled: '{jobModel.isdeleted}',
                    },
                },
                {
                    xtype: 'button',
                    text: 'Confirm',
                    iconCls: 'ion-ios-checkmark',
                    handler: 'onConfirmDocumentButtonTap',
                    bind: {
                        hidden: '{confirmed}',
                        disabled: '{jobModel.isdeleted}',
                    },
                }, {
                    xtype: 'button',
                    text: 'Save',
                    iconCls: 'ion-ios-done-all-outline',
                    handler: 'onSaveButtonTap',
                    bind: {
                        hidden: '{isdeleted}',
                    },
                }, {
                    xtype: 'button',
                    text: 'Cancel',
                    iconCls: 'ion-ios-close-outline',
                    handler: 'onCancelButtonTap',
                }],
        },
    ],
});
