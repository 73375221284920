import ADMIN_CONSTANTS from 'client/admin/constants';
import 'FieldServices/proxy/PoweredNowApi';

export default Ext.define('FieldServices.store.Payment', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.Payment'],
    alias: 'store.Payment',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.Payment',
        storeId: 'Payment',
        remoteFilter: true,
        remoteSort: true,
        extraKeys: {
            byPaymentId: 'id',
        },
        dependantStores: ['PaymentType'],
    },
    storeId: 'Payment',

    proxy: {
        type: 'PoweredNowApi',
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/PaymentWithHeaders`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',
        },
        extraParams: {
            requiredFields: ['company_id', 'isdeleted', 'is_archived'],
        },
    },
    pageSize: ADMIN_CONSTANTS.ADMIN_LIMITS.PAGE_SIZE_DISABLE,
});
