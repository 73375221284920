import { UserActivityMonitor } from '@powerednow/client-shared/modules/UserActivityMonitor';

export default Ext.define('Shared.mixins.LogoutInactiveUser', {
    extend: 'Ext.Mixin',
    mixinConfig: {
        before: {
            destroy: 'beforeDestroy',
            init: 'beforeInit',
        },
    },
    mixinId: 'logoutinactiveuser',

    beforeInit() {
        this.activityMonitor = new UserActivityMonitor(14400000); // 4 * 60 * 60 * 1000
    },

    startActivityTracking() {
        this.stopActivityTracking();
        this.interval = setInterval(() => {
            if (!this.activityMonitor.isActive) {
                this.doLogout();
            }
        }, 1000);
    },

    stopActivityTracking() {
        clearInterval(this.interval);
    },

    doLogout() {
        this.stopActivityTracking();
        AuthManager.logout();
    },

    beforeDestroy() {
        this.stopActivityTracking();
    },
});
