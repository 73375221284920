export default Ext.define('FieldServices.view.company.tools.sumup.QueryCheckoutController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.QueryCheckoutController',
    control: {
        checkoutId: true,
        queryResult: true,
        receiptImage: true,
        createMissingPayment: true,
    },
    onCancelButtonTap() {
        this.getView().close();
    },

    isSuccessfulCheckout(transactions) {
        if (Array.isArray(transactions)) {
            return transactions.some(transaction => transaction.status === 'SUCCESSFUL');
        }
        return false;
    },
    async onTapCreateMissingPayment() {
        const response = await FieldServices.app.callAPI({
            method: 'POST',
            url: `api/admindata/company/createMissingSumupPayment/${this.getViewModel().get('companyId')}`,
            jsonData: { checkoutId: this.response.id, checkoutReference: this.response.checkout_reference },
        });
        this.getQueryResult().setValue(JSON.stringify(response, null, 4));
    },
    async onTapQueryCheckout() {
        this.response = null;
        this.getCreateMissingPayment().setDisabled(true);
        const checkoutId = this.getCheckoutId().getValue();
        const response = await FieldServices.app.callAPI({
            method: 'POST',
            url: `api/admindata/company/querySumupCheckout/${this.getViewModel().get('companyId')}`,
            jsonData: { checkoutId },
        });

        this.getQueryResult().setValue(JSON.stringify(response, null, 4));
        this.response = response;
        if (this.isSuccessfulCheckout(response?.transactions)) {
            this.getCreateMissingPayment().setDisabled(false);
        }
    },
});
