export default Ext.define('FieldServices.view.company.tools.sumup.QueryTransactionViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.QueryTransactionController',
    control: {
        transactionId: true,
        queryResult: true,
        receiptImage: true,
    },
    onCancelButtonTap() {
        this.getView().close();
    },
    async onQueryTransaction() {
        const transactionId = this.getTransactionId().getValue();
        const response = await FieldServices.app.callAPI({
            method: 'POST',
            url: `api/admindata/company/querySumupTransaction/${this.getViewModel().get('companyId')}`,
            jsonData: { transactionId },
        });

        this.getQueryResult().setValue(JSON.stringify(response, null, 4));
        const receipt = response?.links?.find(link => link.rel === 'receipt' && link.type === 'image/png');
        this.getReceiptImage().setSrc(receipt?.href);
    },
});
