export default Ext.define('FieldServices.view.company.tools.CompanyToolsView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.company.tools.CompanyToolsController',
        'FieldServices.view.company.tools.CompanyToolsViewModel',
    ],

    xtype: 'companyToolsView',
    cls: 'companyTools',

    controller: 'CompanyToolsController',
    viewModel: 'CompanyToolsViewModel',

    listeners: {

    },
    bind: {
        masked: '{saveLoadMask}',
    },
    layout: 'vbox',
    flex: 1,
    scrollable: true,

    items: [{
        xtype: 'button',
        text: 'Query sumup transaction',
        itemId: 'queryTransaction',
    }, {
        xtype: 'button',
        text: 'Query sumup checkout',
        itemId: 'queryCheckout',
    }, {
      xtype: 'button',
      text: 'Get message limit overview',
      itemId: 'messageLimitButton',
    }],
});
