import objectId from 'shared/modules/utilities/objectId';

Ext.define('FieldServices.view.widgets.filters.DateRangeFilter', {
    extend: 'Ext.field.Container',
    nameHolder: true,
    xtype: 'dateRangeFilter',
    layout: 'vbox',
    cls: 'dateRangeFilter',
    errorTarget: 'under',

    requires: [
        'Shared.Components.field.DateTimeField',
    ],

    config: {
        mode: 'datetime',
        fieldName: null,
        tableName: null,
        fieldConfigs: null,
        fixedRangeMode: null,
        selectableRangeLimit: null,
        maxDate: null,
        dbType: 'mysql',
        convertToObjectId: false,
        inclusive: false,
    },

    viewModel: {
        data: {
            rangeMode: 'any',
            dbType: 'mysql',
            rangeModeFixed: false,
        },
        formulas: {
            selectorCls: {
                bind: '{rangeMode}',
                get(rangeMode) {
                    return rangeMode === 'any' ? ['dateRangeFilterModeSelector'] : ['dateRangeFilterModeSelector', 'withFilters'];
                },
            },
            placeholderWidth: {
                bind: {
                    rangeMode: '{rangeMode}',
                    rangeModeFixed: '{rangeModeFixed}',
                },
                get({ rangeMode, rangeModeFixed }) {
                    if (rangeModeFixed || rangeMode === 'inRange') {
                        return 0;
                    }
                    if (rangeMode === 'any') {
                        return 2.2;
                    }
                    return 1.1;
                },
            },
            rangeStartOperator: {
                bind: {
                    rangeMode: '{rangeMode}',
                    dbType: '{dbType}',
                },
                get({ rangeMode, dbType }) {
                    const operatorOptions = {
                        mysql: ['>', '>='],
                        mongo: ['$gt', '$gte'],
                    };
                    return rangeMode === 'laterThan' ? operatorOptions[dbType][0] : operatorOptions[dbType][1];
                },
            },
            rangeEndOperator: {
                bind: {
                    rangeMode: '{rangeMode}',
                    dbType: '{dbType}',
                },
                get({ rangeMode, dbType }) {
                    const operatorOptions = {
                        mysql: ['<', '<='],
                        mongo: ['$lt', '$lte'],
                    };
                    return rangeMode === 'earlierThan' ? operatorOptions[dbType][0] : operatorOptions[dbType][1];
                },
            },
        },
    },

    constructor(config) {
        config.items = this.getItemsConfig(config);
        this.callParent(arguments);
        if (config.fixedRangeMode) {
            this.getViewModel().setData({ rangeModeFixed: true, rangeMode: config.fixedRangeMode });
        }
        if (config.dateFormat) {
            this.setDateFormat(config.dateFormat);
        }
        if (config.dbType) {
            this.getViewModel().setData({ dbType: config.dbType });
        }
        this.down('#dateRangeFrom').on('change', this.validateRange, this);
        this.down('#dateRangeTo').on('change', this.validateRange, this);
    },

    setDateRange(values) {
        if (values.from) {
            this.down('#dateRangeFrom').setValue(values.from);
        }
        if (values.to) {
            this.down('#dateRangeTo').setValue(values.to);
        }
    },

    setDateFormat(format) {
        this.getDateFields().forEach(field => field.updateDateFormat(format));
    },

    getDateFields(onlyActive = false) {
        const fieldIds = ['#dateRangeFrom', '#dateRangeTo'];
        const fields = fieldIds.map(id => this.down(id));
        return fields.filter(field => !onlyActive || field.isVisible());
    },

    getFilters() {
        const filters = this.getDateFields(true)
            .reduce((filterValues, item) => [...filterValues, ...item.getFilters()], []);
        const includesMap = {
            '<': '<',
            '>': '>=',
        };

        return filters.map((filter, index) => ({
            ...filter,
            id: index,
            value: this.config.convertToObjectId ? objectId(new Date(filter.value)) : filter.value,
            operator: this.config.inclusive ? includesMap[filter.operator] : filter.operator,
        }));
    },

    clearFilters() {
        const { rangeMode, rangeModeFixed } = this.getViewModel().getData();
        if (rangeMode === 'dateRange' || rangeModeFixed) {
            return;
        }
        this.getDateFields()
            .forEach(item => {
                item.setValue(null);
            });
    },

    isEnabledDateRange() {
        return this.getViewModel().get('rangeMode') !== 'any';
    },

    validateRange() {
        const from = this.down('#dateRangeFrom').getValue();
        const to = this.down('#dateRangeTo').getValue();
        const { value: limit, intervalType } = this.getSelectableRangeLimit() || { limit: false };
        const intervalTypeStringMap = {
            [Ext.Date.DAY]: 'day',
            [Ext.Date.MONTH]: 'month',
            [Ext.Date.YEAR]: 'year',
        };
        let errorMessage = '';
        if (limit && Ext.Date.diff(from, to, intervalType) > limit) {
            errorMessage = `Selected range cannot be longer than ${limit} ${intervalTypeStringMap[intervalType]}(s)`;
        }
        this.setErrorMessage(errorMessage);
        this.fireEvent('validated', errorMessage === '');
    },

    getItemsConfig(config) {
        return [
            {
                xtype: 'formpanel',
                layout: 'hbox',
                flex: 1,
                title: config.titleAlign !== 'left' && config.title ? config.title : null,
                items: [
                    {
                        flex: 0.5,
                        xtype: 'container',
                        layout: 'vbox',
                        items: [{ flex: 1, html: config.title, cls: 'x-paneltitle' }],
                        hidden: !config.title || config.titleAlign !== 'left',
                    },
                    {
                        xtype: 'selectfield',
                        cls: 'dateRangeFilterModeSelector',
                        options: [
                            { text: 'Any', value: 'any' },
                            { text: 'Later than', value: 'laterThan' },
                            { text: 'Earlier than', value: 'earlierThan' },
                            { text: 'In range', value: 'inRange' },
                        ],
                        flex: 1,
                        bind: {
                            value: '{rangeMode}',
                            hidden: '{rangeModeFixed}',
                        },
                    },
                    {
                        cls: [
                            'separator',
                            'filterColumn',
                        ],
                        bind: {
                            hidden: '{rangeMode === "any" || rangeModeFixed}',
                        },
                        flex: 0.1,
                    },
                    {
                        xtype: 'powerednow.datetimefield',
                        itemId: 'dateRangeFrom',
                        dateFormat: 'Y-m-d',
                        isField: false,
                        mode: config.mode,
                        fieldName: config.fieldName || config.name,
                        name: `${config.name}_from`,
                        tableName: config.tableName,
                        fieldConfigs: config.fieldConfigs,
                        timeIncrement: config.timeIncrement,
                        value: new Date(),
                        maxDate: config.maxDate,
                        bind: {
                            filterOperator: '{rangeStartOperator}',
                            hidden: '{rangeMode === "any" || rangeMode ==="earlierThan"}',
                        },
                        flex: 1,
                    },
                    {
                        cls: [
                            'separator',
                            'rangeDash',
                        ],
                        bind: {
                            hidden: '{rangeMode !== "inRange"}',
                        },
                        flex: 0.1,
                    },
                    {
                        xtype: 'powerednow.datetimefield',
                        itemId: 'dateRangeTo',
                        dateFormat: 'Y-m-d',
                        isField: false,
                        mode: config.mode,
                        fieldName: config.fieldName || config.name,
                        name: `${config.name}_to`,
                        tableName: config.tableName,
                        fieldConfigs: config.fieldConfigs,
                        timeIncrement: config.timeIncrement,
                        value: new Date(),
                        maxDate: config.maxDate,
                        bind: {
                            filterOperator: '{rangeEndOperator}',
                            hidden: '{rangeMode === "any" || rangeMode ==="laterThan"}',
                        },
                        flex: 1,
                    },
                    {
                        bind: {
                            flex: '{placeholderWidth}',
                        },
                    },
                ],
            },

        ];
    },

});
