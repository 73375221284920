const _ = require('lodash');
const moment = require('moment');
import { FREE_SUBSCRIPTION_SETUPS } from '@powerednow/shared/constants/subscription';

Ext.define('FieldServices.view.company.purchases.subscriptions.add.AddSubscriptionViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.addSubscriptionViewController',
    xtype: 'addSubscriptionViewController',

    control: {
        creationReasonSelector: {
            change: 'onSelectCreationReason',
        },
        cancelButton: {
            tap: 'onCancelButtonTap',
        },
        submitButton: {
            tap: 'onSubmitButtonTap',
        },
        expires_date: true,
    },

    config: {
        companyId: null,
        purchaseSetups: [],
        subscriptionReasons: {},
        selectDefaultValue: {
            text: '-',
            value: '',
        },
        beforeInit: '_setSelectFields',
    },

    init() {
        this._setSelectFields();
    },

    onSubmitButtonTap() {
        this._submitForm()
            .catch(handleClientError);
    },

    onCancelButtonTap() {
        this.getView().hide();
    },

    onSelectCreationReason(field, newValue) {
      const selectedSetup = Object.values(FREE_SUBSCRIPTION_SETUPS).find(subSetup => subSetup.PURCHASE_ORIGIN_ID === newValue);
      this.setExpirationDate(selectedSetup.DEFAULT_LENGTH);
    },

    async _submitForm() {
        const validationResult = await this.getView().validate();
        if (validationResult.isValid) {
            this._sendDataToServer();
        }
    },

    async _sendDataToServer() {
        try {
            const data = this._getFormData();
            await this.showLoader(this.getView());
            return await this._callApi(data);
        } finally {
            this.getView().fireEvent('dataSent', {});
            this.getView().hide();
            await this.clearLoader(this.getView());
        }
    },

    setExpirationDate(days) {
        const purchaseDate = this.getView().down('#purchaseDate').getValue();
        const date = moment(purchaseDate).add(days, 'days').toDate();
        this.getView().down('#expiresDate').setValue(date);
    },

    _generateRandomString() {
        return btoa(Math.random());
    },

    _getFormData() {
        const fields = this.getView().getValues();

        fields.product = this._getPurchaseSetup(fields.product_id).ios_product_id;
        fields.companyId = parseInt(this.getCompanyId(), 10);
        fields.isverified = 1;
        fields.count = 1;
        fields.quantity = 1;
        fields.originalPurchaseDate = fields.purchaseDate;
        fields.dt_created = new Date();
        fields.receipt = this._generateRandomString();
        fields.transaction_id = this._generateRandomString();

        const audit = {
            event_type: CONSTANTS.ADMIN_AUDIT_TRAIL.EVENT_TYPES.ADD_MARKETING_SUBSCRIPTION,
            note: fields.note,
        };
        return {
            audit,
            fields,
        };
    },

    async _callApi(detailsData) {
        return FieldServices.app.callAPI({
            method: 'POST',
            url: `api/admindata/audited-company-table/Purchases/${this.getCompanyId()}`,
            jsonData: detailsData,
        });
    },

    async _setSelectFields() {
        await this.loadPurchaseSetupOptions();
        this.loadSubscriptionReasonOptions();
    },

    async loadPurchaseSetupOptions() {
      const purchaseSetups = await this._loadPurchaseSetups();
      if (!purchaseSetups) {
        return;
      }
      this.setPurchaseSetups(purchaseSetups);
      const sortedPurchaseSetup = this._sortPurchaseSetups(purchaseSetups);
      const options = sortedPurchaseSetup.reduce((prev, item) => {
        if (item.data.type !== CONSTANTS.PURCHASE_SETUP.TYPE.SUBSCRIPTION || item.data.soldonbt === 0) {
          return prev;
        }
        return [...prev, {
          text: `${item.data.product_name} - ${item.data.ios_product_id} - ${item.data.dt_created}`,
          value: item.data.id,
        }];
      }, [this.getSelectDefaultValue()]);

        this.getView().down('#selectPurchaseSetup').setOptions(options);
    },

    loadSubscriptionReasonOptions () {
      const options = Object.values(FREE_SUBSCRIPTION_SETUPS).map(subSetup => {
        return {
          text: subSetup.LABEL,
          value: subSetup.PURCHASE_ORIGIN_ID,
        }
      });
      this.getView().down('#creationReasonSelector').setOptions(options);
    },

    async _loadPurchaseSetups() {
        return Ext.getStore('PurchaseSetup').waitToLoad();
    },

    _sortPurchaseSetups(purchaseSetups) {
        return _.cloneDeep(purchaseSetups).sort((a, b) => String(a.data.ios_product_id || '').localeCompare(b.data.ios_product_id));
    },

    _getPurchaseSetup(id) {
        const purchaseSetups = this.getPurchaseSetups().filter(item => item.data.id === id);
        return _.get(purchaseSetups, '[0].data');
    },
});
