Ext.define('FieldServices.view.report.monthlyReports.detailed.filter.MonthlyReportsFilterView', {
    extend: 'Ext.form.Panel',

    requires: [
        'FieldServices.view.report.monthlyReports.detailed.filter.MonthlyReportsFilterViewController',
        'FieldServices.view.widgets.filters.DateRangeFilter',
        'FieldServices.view.widgets.filters.WhiteLabelFilter',
    ],

    xtype: 'monthlyReportsFilterView',

    controller: 'monthlyReportsFilterViewController',

    scrollable: true,

    layout: 'hbox',

    constructor(config) {
        config.items = this.getItemsConfig();
        this.callParent(arguments);
    },

    getDisplayValue() {
        return this.down('#monthSelect').getFormattedDate();
    },

    setFiltersAndLoad(month, whiteLabelId) {
        this.down('#monthSelect').setValue(new Date(month));
        this.down('#whiteLabelSelect').setSelection(whiteLabelId);
        const filterData = this.getFilters();
        this.fireEvent('changeFilter', { filterData });
    },

    getItemsConfig() {
        const initialDate = new Date();
        initialDate.setHours(0, 0, 0, 0);
        initialDate.setMonth(initialDate.getMonth() - 1, 1);
        const initialComparisonDate = new Date(initialDate);
        initialComparisonDate.setMonth(initialComparisonDate.getMonth() - 1 , 1);
        return [
            {
                xtype: 'container',
                layout: 'vbox',
                flex: 1,
                items: [{
                  xtype: 'powerednow.datetimefield',
                  itemId: 'comparisonMonthSelect',
                  mode: 'date',
                  name: 'comparisonMonth',
                  label: 'Comparison Month:',
                  dateFormat: 'Y-m',
                  value: initialComparisonDate,
                  maxDate: initialComparisonDate,
                  tableName: 'MonthlyReports',
                  isField: true,
                  shouldValueFormattedByMode: false,
                },{
                    xtype: 'powerednow.datetimefield',
                    itemId: 'monthSelect',
                    mode: 'date',
                    label: 'Month:',
                    name: 'month',
                    dateFormat: 'Y-m',
                    value: initialDate,
                    maxDate: initialDate,
                    tableName: 'MonthlyReports',
                    isField: true,
                    shouldValueFormattedByMode: false,
                },
                ],
            },
            {
                xtype: 'container',
                layout: 'vbox',
                flex: 1,
                items: [
                    {
                        xtype: 'whiteLabelFilter',
                        includeTest: false,
                        includeObsolete: true,
                        itemId: 'whiteLabelSelect',
                    },

                ],
            },
            {
                xtype: 'container',
                docked: 'bottom',
                layout: 'hbox',
                items: [{
                    xtype: 'button',
                    text: 'Load',
                    cls: 'btn',
                    handler: 'onChangeFilter',
                }, {
                    xtype: 'checkbox',
                    label: 'Exclude Marketing Subscriptions',
                    labelWidth: 'auto',
                    itemId: 'promoExcludeCheckbox',
                }, {
                    xtype: 'button',
                    text: 'Export as xls',
                    iconCls: 'ion-document',
                    handler: 'onDownloadCsvFile',
                    right: '0',
                }],
            },
        ];
    },

});
