import controller from './MessageLimitStatsViewController';

export default Ext.define('FieldServices.view.company.tools.messageLimitStats.MessageLimitStatsView', {
  extend: 'Ext.panel.Panel',
  xtype: 'messageLimitStatsView',
  title: 'Message Limit Overview',
  tools: [{
    type: 'close',
    handler: 'onCancelButtonTap',
  }],
  controller,
  layout: {
    type: 'vbox',
    align: 'stretch',
  },
  items: [
    {
      xtype: 'container',
      layout: {
        type: 'hbox',
        pack: 'space-around'
      },
      itemId: 'statsContainer',
      padding: '2em',
      items: [],
    },
    {
      xtype: 'button',
      itemId: 'refreshLimitStatsBtn',
      text: 'Refresh',
      cls: 'btn btn--centered',
      hidden: true,
    }
  ],
});
