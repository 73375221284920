import ComplexModel from 'shared/modules/complexData/documentItem';

export default Ext.define('FieldServices.model.DocumentItem', {
    extend: 'FieldServices.model.override.Model',

    statics: {
        complexModel: ComplexModel,
    },
    requires: [
        'Ext.data.proxy.Rest',
    ],
    idProperty: 'clientId',
    config: {
        fields: [],
    },
});
