Ext.define('FieldServices.view.company.user.edit.UserEditView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.company.user.edit.UserEditViewController',
        'FieldServices.view.company.user.edit.UserEditViewModel',
        'FieldServices.view.company.user.edit.LoginWithUserModalView',
        'FieldServices.view.message.newPushMessageView',
    ],

    xtype: 'userEditView',

    controller: 'userEditViewController',

    viewModel: 'UserEditViewModel',

    itemId: 'UserPopupId',

    scrollable: true,

    title: 'Edit User',
    emptyText: 'empty',

    tools: [{
        type: 'close',
        handler: 'onCloseToolButtonTap',
    }],

    keyMap: {
        ESC: {
            handler: 'onEscKeydown',
            scope: 'controller',
            event: 'keydown',
        },
    },

    cls: 'panel_padding',

    defaultType: 'textfield',

    items: [
        {
            bind: {
                value: '{email}',
            },
            label: 'email',
            labelAlign: 'top',
            name: 'email',
        }, {
            xtype: 'container',
            layout: 'hbox',
            items: [{
                bind: {
                    value: '{decodedFirstName}',
                },
                xtype: 'textfield',
                label: 'firstname',
                labelAlign: 'top',
                name: 'firstname',
                flex: 1,
            }, {
                bind: {
                    value: '{decodedLastName}',
                },
                xtype: 'textfield',
                label: 'lastname',
                labelAlign: 'top',
                name: 'lastname',
                flex: 1,
            }],
        }, {
            bind: {
                value: '{password}',
            },
            label: 'password',
            labelAlign: 'top',
            name: 'password',
        }, {
            xtype: 'container',
            layout: 'hbox',
            items: [{
                bind: {
                    value: '{socialId}',
                },
                xtype: 'textfield',
                label: 'social Id',
                labelAlign: 'top',
                name: 'socialId',
                disabled: true,
                flex: 1,
            }, {
                bind: {
                    value: '{socialProvider}',
                },
                xtype: 'textfield',
                label: 'social Provider',
                labelAlign: 'top',
                name: 'socialProvider',
                disabled: true,
                flex: 1,
            }],
        }, {
            xtype: 'container',
            items: [{
                xtype: 'selectfield',
                itemId: 'profileSelectField',
                name: 'profile',
                label: 'Profile',
                autoSelect: false,
                bind: {
                    value: '{userProfileId}',
                    disabled: '{is_main || !is_active || !userProfileId}',
                },
                displayField: 'name',
                valueField: 'id',
                store: 'UserProfile',
            }],
        },
        {
            xtype: 'fieldset',
            title: 'Admin Access',
            bind: {
                masked: '{currentAdminStatus === null}',
            },
            items: [
                {
                    xtype: 'container',
                    flex: 1,
                    style: {
                        color: 'gray',
                        textAlign: 'center',
                    },
                    html: '<h2>Insufficient permissions</h2><h3><i>You can\'t modify admin access for yourself, or users with higher permission level.</i></h3>',
                    bind: {
                        hidden: '{canModifyAdminPermission || currentAdminStatus === null}',
                    },
                },
                {
                    xtype: 'selectfield',
                    itemId: 'currentAdminStatus',
                    name: 'currentAdminStatus',
                    bind: {
                        options: '{currentAdminOptions}',
                        value: '{currentAdminStatus}',
                        hidden: '{!canModifyAdminPermission || currentAdminStatus === null}',
                    },
                }],
        },

        {
            xtype: 'toolbar',
            docked: 'bottom',
            scrollable: true,
            bodyCls: 'showOverflowOnMobile',
            items: ['->', {
                xtype: 'button',
                itemId: 'sendMessageButton',
                text: 'Send message',
                iconCls: 'ion-android-mail',
                bind: {
                    hidden: '{isdeleted}',
                },
            }, {
                xtype: 'button',
                itemId: 'loginUserButton',
                text: 'login with user',
                iconCls: 'ion-log-in',
                bind: {
                    hidden: '{isdeleted}',
                },
            }, {
                xtype: 'button',
                itemId: 'loginWithPartnerButton',
                text: 'Login with Partner',
                iconCls: 'ion-log-in',
                bind: {
                    hidden: '{!whiteLabelDesktopUrl}',
                },
            }, {
                xtype: 'button',
                itemId: 'deleteSocialButton',
                text: 'delete social',
                iconCls: 'ion-ios-trash-outline',
                ui: '-danger',
            }, {
                xtype: 'button',
                itemId: 'deleteUserButton',
                text: 'delete user',
                iconCls: 'ion-ios-trash-outline',
                ui: '-danger',
                bind: {
                    hidden: '{isdeleted}',
                },
            }, {
                xtype: 'button',
                itemId: 'restoreUserButton',
                text: 'restore',
                iconCls: 'ion-ios-undo',
                bind: {
                    hidden: '{!isdeleted}',
                },
            }, {
                xtype: 'button',
                itemId: 'submitButton',
                text: 'Submit',
                iconCls: 'ion-ios-checkmark-outline',
                ui: '-primary',
            }, {
                xtype: 'button',
                itemId: 'closeButton',
                text: 'Cancel',
                iconCls: 'ion-ios-close-outline',
            }],
        },
    ],

});
