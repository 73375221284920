import settingsKeys from '@powerednow/admin/FieldServices/model/SettingsKeys';
import { FEATURES } from '@powerednow/shared/constants/subscription';
import SETTINGS_KEYS from '@powerednow/shared/constants/settingsKeys';
import PURCHASE_SETUP from '@powerednow/shared/constants/purchaseSetup';

Ext.define('FieldServices.view.company.settings.CompanySettingsViewModel', {
    extend: 'Ext.app.ViewModel',

    alias: 'viewmodel.companySettingsViewModel',
    data: {
        newCertificatesBlockedCurrentValue: null,
        newCertificatesBlockedValue: null,
        maxEmailLimitPerMonthCurrentValue: null,
        maxEmailLimitPerMonthValue: null,
        renewableEnergyFormsEnabledValue: null,
        renewableEnergyFormsEnabledCurrentValue: null,
        canChangeWhiteLabel: false,
        isTopLevelAdmin: false,
        maxEmailLimitPerMonthVisible: false,
        maxEmailLimitPerMonthVisibleAfter: false,
        syncingStoreCount: 0,
    },
    stores: {

        subscriptionInfoStore: {
            type: 'PurchasesInfo',
            model: 'FieldServices.model.PurchasesInfo',
            storeId: 'SubscriptionInfoStore',
            autoLoad: true,
            remoteSort: false,

            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                }],
            sorters: [
                {
                    property: 'purchase_date',
                    direction: 'DESC',
                }],
        },

        companySettingsStore: {
            type: 'CompanySettings',
            autoLoad: true,
            filters: [
                {
                    property: 'company_id',
                    value: '{company.id}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                },
            ],
        },

        featureStore: {
            type: 'Feature',
            autoLoad: true,
            filters: [
                {
                    property: 'company_id',
                    value: '{company.id}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: '=',
                    value: false,
                },
            ],
            sorters: [{
                property: 'dt_trial_expires',
                direction: 'ASC',
            }],
        },

        businessTypeOptionStore: {
            source: 'BusinessTypes',
            autoLoad: true,
            filters: [
                {
                    property: 'id',
                    value: 0,
                    operator: '!=',
                }],
        },

        productTierToSubscriptionStore: {
            source: 'ProductTierToSubscription',
            autoLoad: true,
            filters: [
                {
                    property: 'id',
                    value: 0,
                    operator: '!=',
                }],
        },

        productTierToFeatureStore: {
            source: 'ProductTierToFeature',
            autoLoad: true,
            filters: [
                {
                    property: 'id',
                    value: 0,
                    operator: '!=',
                }],
        },
    },

    formulas: {

        saveLoadMask: {
            bind: {
                syncCount: '{syncingStoreCount}',
                certsSaved: '{newCertificatesDirty}',
            },
            get({ syncCount, certsSaved }) {
                return syncCount > 0 && !certsSaved ? { xtype: 'loadmask', message: 'Saving settings ...' } : false;
            },
        },

        companyWhiteLabelId: {
            bind: '{company}',
            get(companyRecord) {
                return companyRecord?.data.whitelabel_id;
            },
            set(value) {
                const companyRecord = this.get('company');
                return companyRecord.set('whitelabel_id', value);
            },
        },

        companyIsTest: {
            bind: '{company}',
            get(companyRecord) {
                return companyRecord?.data.is_test;
            },
            set(value) {
                const companyRecord = this.get('company');
                return companyRecord.set('is_test', value);
            },
        },

        maxEmailLimitPerMonthSettingRecordValue: {
            bind: {
                bindTo: {
                    subscriptionInfoStore: '{subscriptionInfoStore}',
                    settingsStore: '{companySettingsStore}',
                    loadCount: '{companySettingsStore.loadCount}',
                    productTierToSubscriptionStore: '{productTierToSubscriptionStore}',
                    productTierToFeatureStore: '{productTierToFeatureStore}',
                },
                deep: true,
            },
            async get({
                settingsStore, subscriptionInfoStore, productTierToSubscriptionStore, productTierToFeatureStore,
            }) {
                const record = settingsStore.findExactRecord('setting_key_id', SETTINGS_KEYS.MAX_EMAIL_LIMIT_PER_MONTH);
                const activeSub = subscriptionInfoStore.getRecords().filter(record => record.get('isActive') && record.data.product_name !== 'Free period' && record.get('type') !== PURCHASE_SETUP.TYPE.CONSUMPTION)[0];
                if (!record && !activeSub) { // We have a Free period without a set value OR we have some error
                    this.set('maxEmailLimitPerMonthValue', null);
                    this.set('maxEmailLimitPerMonthCurrentValue', null);
                    return false;
                }

                if (!record) { // We have a subscription but no custom value
                    if (activeSub?.data?.purchase_setup_id) {
                        await Ext.getStore('ProductTierToSubscription').waitToLoad();
                        await Ext.getStore('ProductTierToFeature').waitToLoad();
                        const productTierToSubscriptionRecord = productTierToSubscriptionStore.data.items.find(item => item.data.purchase_setup_id === activeSub.data.purchase_setup_id);
                        const emailSetting = productTierToFeatureStore.data.items.find(item => item.data.product_feature_id === FEATURES.EMAIL_LIMIT && item.data.product_tier_id === productTierToSubscriptionRecord.data.product_tier_id);
                        this.set('maxEmailLimitPerMonthValue', emailSetting?.data?.value || null);
                        this.set('maxEmailLimitPerMonthCurrentValue', emailSetting?.data?.value || null);
                        this.set('maxEmailLimitPerMonth', emailSetting?.data?.value || settingsKeys.getDefaultValue(SETTINGS_KEYS.MAX_EMAIL_LIMIT_PER_MONTH));
                        emailSetting.isTierSetting = true;
                        return emailSetting || false;
                    }
                    return false;
                }

                // We have a custom record
                this.set('maxEmailLimitPerMonthValue', record.data.setting_value);
                this.set('maxEmailLimitPerMonthCurrentValue', record.data.setting_value);
                this.set('maxEmailLimitPerMonth', record.data.setting_value);
                return record;
            },
        },

        maxEmailLimitPerMonth: {
            bind: '{maxEmailLimitPerMonthSettingRecordValue}',
            async get(record) {
                if (!record) {
                    return settingsKeys.getDefaultValue(SETTINGS_KEYS.MAX_EMAIL_LIMIT_PER_MONTH); // Trial
                }
                return record?.data?.value || record.data.setting_value;
            },
            async set(value) {
                let record = this.get('maxEmailLimitPerMonthRecord');
                if (!record || record.isTierSetting) {
                    record = Ext.create('FieldServices.model.CompanySettings', {
                        setting_key_id: SETTINGS_KEYS.MAX_EMAIL_LIMIT_PER_MONTH,
                        setting_value: value,
                        company_id: this.get('companyId'),
                    });
                    this.data.companySettingsStore.add(
                        record,
                    );
                    this.set('maxEmailLimitPerMonthRecord', record);
                }
                this.set('maxEmailLimitPerMonthCurrentValue', value);
                record.set('company_id', this.get('companyId')); // This is a safety solution because companies produced with the old admin throw an error if it is not present
                return record.set('setting_value', value);
            },
        },

        companyBusinessTypeId: {
            bind: '{company}',
            get(companyRecord) {
                return companyRecord?.data.businesstypes_id;
            },
            set(value) {
                const companyRecord = this.get('company');
                if (companyRecord) {
                    return companyRecord.set('businesstypes_id', value);
                }
            },
        },

        companyTeamSize: {
            bind: '{company}',
            get(companyRecord) {
                return companyRecord?.data.teamSize;
            },
            set(value) {
                const companyRecord = this.get('company');
                return companyRecord.set('teamSize', value);
            },
        },

        companyVATScheme: {
            bind: '{company}',
            get(companyRecord) {
                return companyRecord?.data.vat_scheme;
            },
            set(value) {
                const companyRecord = this.get('company');
                return companyRecord.set('vat_scheme', value);
            },
        },

        companyMTDEnabled: {
            bind: '{company}',
            get(companyRecord) {
                return companyRecord?.data.mtd_enabled;
            },
            set(value) {
                const companyRecord = this.get('company');
                return companyRecord.set('mtd_enabled', value);
            },
        },

        companyXeroUsageEnabled: {
            bind: '{xeroIntegrationRecord}',
            get(xeroRecord) {
                return xeroRecord?.data.is_usage_enabled;
            },
            set(value) {
                const xeroRecord = this.get('xeroIntegrationRecord');
                return xeroRecord.set('is_usage_enabled', value);
            },
        },

        maxEmailLimitPerMonthRecord: {
            bind: {
                bindTo: {
                    settingsStore: '{companySettingsStore}',
                    loadCount: '{companySettingsStore.loadCount}',
                },
                deep: true,
            },
            async get({ settingsStore }) {
                let record = settingsStore.findExactRecord('setting_key_id', SETTINGS_KEYS.MAX_EMAIL_LIMIT_PER_MONTH);
                const limit = await this.get('maxEmailLimitPerMonth');
                if (!record) {
                    record = Ext.create('FieldServices.model.CompanySettings', {
                        setting_key_id: SETTINGS_KEYS.MAX_EMAIL_LIMIT_PER_MONTH,
                        setting_value: limit,
                        company_id: this.get('companyId'),
                    });
                    this.data.companySettingsStore.add(
                        record,
                    );
                    this.set('maxEmailLimitPerMonthRecord', record);
                }
                record.set('company_id', this.get('companyId'));
                return record;
            },
        },

        renewableFormSettingRecord: {
            bind: {
                bindTo: {
                    settingsStore: '{companySettingsStore}',
                    loadCount: '{companySettingsStore.loadCount}',
                },
                deep: true,
            },
            async get({ settingsStore }) {
                let record = settingsStore.findRecord('setting_key_id', SETTINGS_KEYS.RENEWABLE_ENERGY_FORMS_ENABLED);
                if (!record) {
                    record = Ext.create('FieldServices.model.CompanySettings', {
                        setting_key_id: SETTINGS_KEYS.RENEWABLE_ENERGY_FORMS_ENABLED,
                        setting_value: 'true',
                        company_id: this.get('companyId'),
                    });
                    this.data.companySettingsStore.add(
                        record,
                    );
                    this.set('renewableFormSettingRecord', record);
                }
                this.set('renewableEnergyFormsEnabledValue', record.data.setting_value.toString());
                this.set('renewableEnergyFormsEnabledCurrentValue', record.data.setting_value.toString());
                return record;
            },
        },

        renewableEnergyFormsEnabled: {
            bind: '{renewableFormSettingRecord}',
            async get(record) {
                if (!record) {
                    return settingsKeys.getDefaultValueBoolean(SETTINGS_KEYS.RENEWABLE_ENERGY_FORMS_ENABLED, 'false');
                }
                return record.data.setting_value !== 'false';
            },
            set(value) {
                const record = this.get('renewableFormSettingRecord');
                record.set('company_id', this.get('companyId')); // This is a safety solution because companies produced with the old admin throw an error if it is not present
                this.set('renewableEnergyFormsEnabledValue', value.toString());
                return record.set('setting_value', value.toString());
            },
        },

        companyFeatureRecord: {
            bind: {
                bindTo: {
                    featureStore: '{featureStore}',
                    loadCount: '{featureStore.loadCount}',
                },
                deep: true,
            },
            get({ featureStore, loadCount }) {
                if (featureStore.isLoading() || loadCount < 1) {
                    return null;
                }
                return featureStore.first();
            },
        },

        trialExpirationDate: {
            bind: '{companyFeatureRecord}',
            get(featureRecord) {
                if (!featureRecord) {
                    return 0;
                }
                return featureRecord.data.dt_trial_expires;
            },
            set(value) {
                const record = this.get('companyFeatureRecord');
                return record.set('dt_trial_expires', value);
            },
        },

        concurrentLoginLimitSetting: {
            bind: '{companyFeatureRecord}',
            get(featureRecord) {
                if (!featureRecord) {
                    return 0;
                }
                const { useConcurrentLoginLimit, concurrentLoginLimit } = featureRecord.data;
                if (useConcurrentLoginLimit) {
                    return concurrentLoginLimit === null ? 1 : 2;
                }
                return 0;
            },
            set(value) {
                const record = this.get('companyFeatureRecord');
                record.set('concurrentLoginLimit', value === 2 ? 1 : null);
                record.set('useConcurrentLoginLimit', value ? 1 : 0);
                return value;
            },
        },

        maxEmailLimitPerMonthSetting: {
            bind: '{maxEmailLimitPerMonthRecord}',
            async get(record) {
                if (!record || record.phantom) {
                    this.set('maxEmailLimitPerMonthVisible', false);
                    this.set('maxEmailLimitPerMonthVisibleAfter', false);
                    this.getView().down('#maxEmailLimitPerMonthField').setValue(0);
                    return 0;
                }
                this.set('maxEmailLimitPerMonthVisibleAfter', true);
                this.set('maxEmailLimitPerMonthVisible', true);
                return 1;
            },
            async set(value) {
                const record = await this.get('maxEmailLimitPerMonthRecord');
                if (value.hasOwnProperty('value')) {
                    this.set('maxEmailLimitPerMonthVisible', !record?.phantom);
                } else {
                    this.set('maxEmailLimitPerMonthVisible', value === 1);
                }

                return record?.set('company_id', this.get('companyId')) || true; // This is a safety solution because companies produced with the old admin throw an error if it is not present
            },
        },

        concurrentLoginLimitValue: {
            bind: {
                bindTo: '{companyFeatureRecord}',
                deep: true,
            },
            get(featureRecord) {
                if (!featureRecord) {
                    return 0;
                }
                const { concurrentLoginLimit } = featureRecord.data;
                return concurrentLoginLimit;
            },
            set(value) {
                const record = this.get('companyFeatureRecord');
                return record.set('concurrentLoginLimit', value);
            },
        },

        renewableEnergyFormsEnabledDirty: {
            bind: {
                current: '{renewableEnergyFormsEnabledCurrentValue}',
                stored: '{renewableEnergyFormsEnabledValue}',
            },
            get({ current, stored }) {
                return current !== stored;
            },
        },

        newCertificatesDirty: {
            bind: {
                current: '{newCertificatesBlockedCurrentValue}',
                stored: '{newCertificatesBlockedValue}',
            },
            get({ current, stored }) {
                return current !== stored;
            },
        },

        maxEmailLimitPerMonthDirty: {
            bind: {
                current: '{maxEmailLimitPerMonthCurrentValue}',
                stored: '{maxEmailLimitPerMonthValue}',
            },
            get({ current, stored }) {
                return current !== stored;
            },
        },

        maxEmailLimitPerMonthVisibleDirty: {
            bind: {
                current: '{maxEmailLimitPerMonthVisible}',
                stored: '{maxEmailLimitPerMonthVisibleAfter}',
            },
            get({ current, stored }) {
                return current !== stored;
            },
        },

        maxEmailLimitPerMonthRecordDirty: {
            bind: {
                record: '{maxEmailLimitPerMonthRecord}',
            },
            get({ record }) {
                // This is an edge case which is present if the company has no previous subscription, you reload the subscription page and come here.
                // In this case the record will have no company_id and it will make the button clickable
                return record.dirty && Object.keys(record.modified).filter(key => key !== 'company_id').length > 0;
            },
        },

        unsavedChanges: {
            bind: {
                bindTo: {
                    maxEmailLimitPerMonthDirty: '{maxEmailLimitPerMonthDirty}',
                    companyDirty: '{company.dirty}',
                    featureDirty: '{companyFeatureRecord.dirty}',
                    maxEmailLimitPerMonthRecordDirty: '{maxEmailLimitPerMonthRecordDirty}',
                    formSettingsDirty: '{renewableEnergyFormsEnabledDirty}',
                    xeroSettingDirty: '{xeroIntegrationRecord.dirty}',
                    certsDirty: '{newCertificatesDirty}',
                    maxEmailLimitPerMonthVisibleDirty: '{maxEmailLimitPerMonthVisibleDirty}',
                },
            },
            get({
                maxEmailLimitPerMonthDirty,
                companyDirty,
                featureDirty,
                formSettingsDirty,
                maxEmailLimitPerMonthRecordDirty,
                xeroSettingDirty,
                certsDirty,
                maxEmailLimitPerMonthVisibleDirty,
            }) {
                return maxEmailLimitPerMonthVisibleDirty || maxEmailLimitPerMonthDirty || companyDirty || featureDirty || formSettingsDirty || xeroSettingDirty || maxEmailLimitPerMonthRecordDirty || certsDirty;
            },
        },
    },
});
