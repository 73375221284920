Ext.define('FieldServices.view.report.monthlyReports.MonthlyReportsViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.monthlyReports',
    data: {
        monthlyDataLoading: false,
        excludePromotionalSubs: false,
        rawPurchasesOfMonth: {},
        rawActiveSubsOfMonth: {},
        rawActiveSubsOfComparisonMonth: {},
        monthlyPurchaseStats: null,
        newPurchaseStats: null,
        activeSubStats: null,
    },
    stores: {
        monthlyPurchaseData: {
            storeId: 'MonthlyPurchaseReportData',
            model: 'FieldServices.model.Custom',
            sorters: [{property: 'expiresDate', direction: 'DESC'}],
        },
        newPurchases: {
            storeId: 'NewMonthlyReportedPurchases',
            model: 'FieldServices.model.Custom',
            sorters: [{property: 'expiresDate', direction: 'DESC'}],
        },
        activeSubsAtMonthEnd: {
            storeId: 'ActiveSubsAtMonthEnd',
            model: 'FieldServices.model.Custom',
            sorters: [{property: 'expiresDate', direction: 'DESC'}],
        },
        activeSubsAtMonthEndCompare: {
          storeId: 'ActiveSubsAtMonthEndCompare',
          model: 'FieldServices.model.Custom',
          sorters: [{property: 'expiresDate', direction: 'DESC'}],
        },
        newRegistrationsInMonth: {
            storeId: 'NewRegistrationsInMonth',
            model: 'FieldServices.model.Custom',
        },
    },
});
