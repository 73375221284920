Ext.define('FieldServices.view.company.CompanyViewController2', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.companyViewController',
    xtype: 'companyViewController',

    urlCompanyId: null,

    control: {
        '': {
            setSubPage: 'onSetSubPage',
        },
        changeCompanyButton: {
            tap: 'changeCompany',
        },
        openInHubspotButton: {
            tap: 'goToHubspot',
        },
        companyManagementPanel: {
            activeItemchange: 'updateUrlSubpage',
        },
    },

    init() {
        this.getView().on('painted', this.selectCompany, this, { args: [false] });
    },

    changeCompany() {
        this.selectCompany(true);
    },

    /**
     * url fire event
     * @param page
     * @param companyId
     */
    onSetSubPage(page, companyId) {
        this.urlCompanyId = companyId;
        if (companyId) {
            this.onCompanySelect(companyId);
        }
        this.getView().getComponent('companyManagementPanel').setActiveItem(parseInt(page, 10));

        // TODO: Set sub-sub page
    },

    updateUrlSubpage(panelCmp) {
        this.updatePageHash(panelCmp._activeItemIndex, 2);
    },

    updatePageHash(value, index) {
        const currentHashItems = window.location.hash.split('/');
        if (index - 1 > currentHashItems.length) {
            currentHashItems.push(value);
        } else {
            currentHashItems[index] = value;
        }
        const newHash = currentHashItems.filter(item => item !== '').join('/');
        if (newHash !== window.location.hash) {
            history.pushState(null, null, newHash);
        }
    },

    selectCompany(force = false) {
        const storedCompanyId = localStorage.getItem('company_id');
        if (!force && storedCompanyId && !this.urlCompanyId) {
            this.onCompanySelect(storedCompanyId);
            return;
        }
        if (force || (!this.getViewModel().get('selectedCompany') && !this.urlCompanyId)) {
            const selector = References.getSelectCompanyPopup();
            selector.on('companySelected', this.onCompanySelect, this, { single: true });
            selector.show();
        }
    },

    onCompanySelect(companyId) {
        localStorage.setItem('company_id', companyId);
        this.getViewModel().setData({ companyId });
        this.updatePageHash(companyId, 3);
        Ext.Viewport.getViewModel().set('companyId', companyId);
        Ext.Viewport.getViewModel().notify();
    },

    goToHubspot() {
        const url = this.getViewModel().get('hubspotRecordUrl');
        Utilities.openNewTab(url);
    },
});
