import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.ProductTierToSubscription', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.ProductTierToSubscription'],
    alias: 'store.ProductTierToSubscription',
    config: {
        model: 'FieldServices.model.ProductTierToSubscription',
        storeId: 'ProductTierToSubscription',
    },
    storeId: 'ProductTierToSubscription',
    proxy: {
        type: 'PoweredNowApi',

        url: `${require('config.js').SERVER_URL}api/admindata/global/ProductTierToSubscription`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',

        },
    },
});
