import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.CompanySettings', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.CompanySettings'],
    alias: 'store.CompanySettings',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.CompanySettings',
        storeId: 'CompanySettings',
        remoteFilter: true,
        remoteSort: true,
    },
    storeId: 'CompanySettings',
    proxy: {
        type: 'PoweredNowApi',
        appendId: false,
        url: `${require('config.js').SERVER_URL}api/admindata/company-tables/CompanySettings`,
        reader: {
            type: 'json',
            rootProperty: 'data',
            totalProperty: 'total',

        },
        extraParams: {
            requiredFields: ['isdeleted'],
        },
    },
});
