Ext.define('FieldServices.view.report.monthlyReports.detailed.MonthlyReportsDetailedView', {
    extend: 'Ext.panel.Panel',

    requires: [
        'FieldServices.view.report.monthlyReports.detailed.filter.MonthlyReportsFilterView',
        'FieldServices.view.report.monthlyReports.detailed.grids.SubscriptionsMonthlyView',
        'FieldServices.view.report.monthlyReports.detailed.grids.NewSubscriptionsView',
        'FieldServices.view.report.monthlyReports.detailed.grids.ActiveSubscriptionsOnMonthEndView',
      'FieldServices.view.report.monthlyReports.detailed.grids.EndOfMonthSubComparison',
        'FieldServices.view.report.monthlyReports.detailed.grids.NewRegistrationsInMonthView',
        'FieldServices.view.report.monthlyReports.detailed.MonthlyReportsDetailedViewController',
    ],

    xtype: 'monthlyReportsDetailed',
    controller: 'monthlyReportsDetailedViewController',
    layout: 'vbox',

    items: [{
        xtype: 'monthlyReportsFilterView',
        itemId: 'monthlyReportsFilter',
    },
    {
        xtype: 'tabpanel',
        itemId: 'gridTabs',
        flex: 1,
        items: [
            {
                tab: {
                    flex: 1,
                    title: 'All subscriptions',
                },
                xtype: 'subscriptionsMonthlyView',
                itemId: 'subscriptionsAllView',
            },
            {
                tab: {
                    flex: 1,
                    title: 'New subscribers',
                },
                xtype: 'newSubscriptionsView',
                itemId: 'subscriptionsNewView',
            },
            {
                tab: {
                    flex: 1,
                    title: 'Active subscribers at the end of month',
                },
                xtype: 'activeSubscriptionsOnMonthEndView',
                itemId: 'activeSubscriptionsOnMonthEndView',
            },
            {
              tab: {
                flex: 1,
                title: 'Compare end of month subscribers',
              },
              xtype: 'endOfMonthSubComparison',
              itemId: 'endOfMonthSubComparison',
            },
            {
                tab: {
                    flex: 1,
                    title: 'New Registrations',
                },
                xtype: 'newRegistrationsInMonthView',
                itemId: 'newRegistrationsInMonthView',
            },
        ],
    }],
    loadDetailedReport(index, month, whiteLabelId) {
        this.down('#monthlyReportsFilter').setFiltersAndLoad(month, whiteLabelId);
        this.down('#gridTabs').setActiveItem(index);
    },
});
