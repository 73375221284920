import moment from 'moment';
import { parse } from '@powerednow/shared/modules/utilities/date';

require('./DateTimeField.scss');

export default Ext.define('Shared.Components.field.dateTimeField.DateTimeField', {
    extend: 'Ext.field.Container',
    alias: 'widget.powerednow.datetimefield',
    requires: [
        'Shared.Components.field.dateTimeField.DateTimeFieldViewModel',
        'Shared.Components.field.TimeField',
        'Ext.field.Date',
        'Ext.field.Time',
        'Shared.Components.field.yearMonthPicker.YearMonthPicker',
    ],
    statics: {
        getSnapTime() {
            return this._snapTime || 1;
        },

        setSnapTime(snapTime) {
            this._snapTime = snapTime;
        },

        getDateFormat() {
            return this._dateFormat || '';
        },

        setDateFormat(dateFormat) {
            this._dateFormat = dateFormat;
        },
    },

    viewModel: {
        type: 'dateTimeFieldViewModel',
    },

    config: {
        value: null,
        disabled: false,
        label: '',
        mode: 'datetime',
        timeFormat: 'G:i',
        name: null,
        timeIncrement: 0,
        placeholder: 'N/A',
        isField: true,
        tableName: null,
        fieldConfigs: null,
        maxDate: null,
        minDate: null,
        filterOperator: null,
    },
    publishes: {
        value: 1,
        filterOperator: 1,
        localisedFormattedValue: 1,
    },
    defaultBindProperty: 'value',
    baseCls: [
        'pn-dateTimeField',
    ],

    layout: 'hbox',
    labelWidth: 0,

    privates: {},

    constructor(config) {
        config.items = this._getItemsConfig(config);
        this.callParent(arguments);
    },

    hideValidationError() {
        this.getDateField()?.hideValidationError();
        this.getTimeField()?.hideValidationError();
    },

    showValidationError(message) {
        const mode = this.getViewModel().get('mode');
        switch (mode) {
            case 'date':
                this.getDateField().showValidationError(message);
                break;
            case 'time':
                this.getTimeField().showValidationError(message);
                break;
            case 'datetime':
                this.callParent([message]);
                break;
            default:
                throw new Error(`Invalid mode: ${mode}`);
        }
    },

    getDateField() {
        return this.down('#dateField');
    },

    getTimeField() {
        return this.down('#timeField');
    },

    updateLabel(newLabel, oldValue) {
        this.getViewModel().set('label', newLabel, oldValue);
    },

    updateMode(newMode, oldValue) {
        this.getViewModel().set('mode', newMode, oldValue);
    },

    updateValue(newValue) {
        this._setOnFields('value', newValue);
        this.publishState('localisedFormattedValue', this.getFormattedDate());
    },

    updateDisabled(newDisabled, oldValue) {
        this._setOnFields('disabled', newDisabled, oldValue);
    },

    updatePlaceholder(newValue) {
        this._setOnFieldsAttr('placeholder', newValue);
    },

    getValue() {
        const value = this.callParent();
        return value ? new Date(value) : value;
    },

    onAnyFieldChange() {
        this.setValue(this.constructDateTimeValue());
    },

    /**
     * @private
     */
    constructDateTimeValue() {
        const dateField = this.getDateField();
        const timeField = this.getTimeField();

        const date = dateField.getValue();
        const time = timeField.getValue();

        if (date || time) {
            const dateTime = new Date(date);
            if (time) {
                if (typeof time === 'string') {
                    const timeFields = time.split(':');
                    dateTime.setHours(timeFields[0] ? timeFields[0] : 0, timeFields[1] ? timeFields[1] : 0);
                } else {
                    dateTime.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
                }
            }

            return dateTime;
        }
        return null;
    },

    applyValue(value) {
        return this.convertValue(value);
    },

    updateDateFormat(value) {
        const dateField = this.getDateField();
        if (value && dateField.getDateFormat() !== value) {
            dateField.setDateFormat(value);
            const { floatedPicker, floatedPickerAlign } = this.getFloatedPickerConfigAndAlign({ ...this.getInitialConfig(), dateFormat: value });
            dateField.setPicker(floatedPicker);
            dateField.setFloatedPickerAlign(floatedPickerAlign);
        }
    },

    updateTimeFormat(value) {
        if (value) {
            this.getTimeField().setFormat(value);
        }
    },

    convertValue(value) {
        if (!value) {
            return value;
        }
        const dateValue = Ext.Date.parse(value, this.getDateField().getDateFormat());
        const timeValue = !dateValue && Ext.Date.parse(value, this.getTimeField().getFormat());
        const dateTimeValue = !timeValue && parse(value, 'Y-MM-DD HH:mm:ss');
        return dateValue || timeValue || dateTimeValue || value;
    },

    _setOnFields(property = '', value = null, oldValue = null) {
        const isValueChanging = property === 'value';

        const dateField = this.getDateField();
        const timeField = this.getTimeField();

        if (isValueChanging) {
            oldValue = this.getValue();
        }

        if (dateField && timeField) {
            const fields = [
                dateField,
                timeField,
            ];
            let convertedValue = value;
            if (typeof value === 'string') {
                convertedValue = this.convertValue(value);
            }
            fields.forEach(field => field.suspendEvents());
            fields.forEach(field => {
                field[`set${property.capitalize()}`].call(field, convertedValue);
            });
            fields.forEach(field => field.resumeEvents());

            if (isValueChanging) {
                this._fireChangeEvent(oldValue);
            }
        }
    },

    _setOnFieldsAttr(property = '', value = null) {
        const dateField = this.getDateField();
        const timeField = this.getTimeField();

        if (dateField && timeField) {
            const fields = [
                dateField,
                timeField,
            ];

            fields.forEach(field => field.suspendEvents());
            fields.forEach(field => {
                field[`set${property.capitalize()}`].call(field, value);
            });
            fields.forEach(field => field.resumeEvents());
        }
    },

    _fireChangeEvent(oldValue) {
        if (this.isFieldsInitialised()) {
            this.fireEvent('change', this, this.getValue(), oldValue);
        }
    },

    _getItemsConfig(config) {
        const itemConfig = this._getDefaultItemConfig(config);
        const { floatedPicker, floatedPickerAlign } = this.getFloatedPickerConfigAndAlign(config);
        return [{
            ...itemConfig,
            xtype: 'datefield',
            readOnly: config.readOnly,
            dateFormat: config.dateFormat || this._getDateFormat(),
            itemId: 'dateField',
            floatedPicker,
            floatedPickerAlign,
            togglePickerOnInputElementTap: true,
            bind: {
                hidden: '{!isDateFieldNeeded}',
                label: '{dateFieldLabel}',
            },
            listeners: {
                change: this.onAnyFieldChange.bind(this),
            },
        }, {
            ...itemConfig,
            ...this._getTimeFieldConfig(config),
            readOnly: config.readOnly,
            itemId: 'timeField',
            bind: {
                hidden: '{!isTimeFieldNeeded}',
                label: '{timeFieldLabel}',
            },
        }];
    },

    _getDefaultItemConfig(config) {
        return {
            label: '',
            labelAlign: config.labelAlign || 'top',
            flex: 1,
            cls: [
                'x-field-solo',
            ],
        };
    },

    getFloatedPickerConfigAndAlign({ dateFormat, maxDate, minDate }) {
        /**
         * Careful there. In app, dateformat cannot be known statically, hence we use static functions on components,
         * which used to configure required date format once a company logs in(as date format can change based on
         * company's country.) This is not the case with admin, but this component used both sides.
         */
        const selectedDateFormat = dateFormat || this._getDateFormat();
        const needsDays = !selectedDateFormat || selectedDateFormat.match(/d/i);
        const pickerComponent = needsDays ? 'datepanel' : 'widget.powerednow.yearmonthpicker';
        const pickerAlign = needsDays ? 'c-c' : 'tr-br';
        return {
            floatedPicker: {
                animation: false,
                xtype: pickerComponent,
                autoConfirm: true,
                floated: true,
                maxDate, // Please note we don't have maxDate 99.99% of time in _app_, admin may broken now @TODO
                showAfterMaxDate: false,
                format: selectedDateFormat,
                minDate,
                listeners: {
                    tabout: 'onTabOut',
                    select: 'onPickerChange',
                    scope: 'owner',
                },
                keyMap: {
                    ESC: 'onTabOut',
                    scope: 'owner',
                },
                nextText: 'Next Month',
                prevText: 'Previous Month',
            },
            floatedPickerAlign: pickerAlign,
        };
    },

    isFieldsInitialised() {
        return this.getDateField() && this.getTimeField();
    },

    _getTimeFieldConfig(config) {
        if (Ext.os.is.Desktop) {
            return this._getCalendarTimeFieldConfig(config);
        }
        return this._getDeviceTimeFieldConfig(config);
    },

    _getCalendarTimeFieldConfig(config) {
        return {
            xtype: 'powerednow.timefield',
            format: config.timeFormat || this.config.timeFormat,
            autoSelect: false,
            increment: this._getSnapTime(config),
            listeners: {
                change: this.onAnyFieldChange.bind(this),
            },
        };
    },

    _getDeviceTimeFieldConfig(config) {
        return {
            xtype: 'timefield',
            errorTarget: 'qtip',
            format: config.timeFormat || this.config.timeFormat,
            minutesSnap: this._getSnapTime(config),
            listeners: {
                change: this.onAnyFieldChange.bind(this),
            },
        };
    },

    _getSnapTime(config) {
        return config.timeIncrement || this.self.getSnapTime();
    },

    _getDateFormat() {
        return this.self.getDateFormat();
    },

    /**
     * @return the stored date string (converted to UTC)
     */
    getDateString() {
        const date = Ext.clone(this.getValue());

        if (date) {
            date.convertToUTC();
            return Ext.Date.format(date, 'Y-m-d H:i:s');
        }

        return '';
    },

    getFormattedDate() {
        const date = Ext.clone(this.getValue());

        if (date) {
            return Ext.Date.format(date, this.getDateField().getDateFormat());
        }

        return '';
    },

    getFormattedTime() {
        const time = this.getTimeField().getInputValue();
        if (time) {
            if (moment.isDate(time)) {
                return Ext.Date.format(time, 'H:m');
            }
            return time;
        }
        return '';
    },

    /**
     * Converts date to local timezone and sets the value.
     * @param date can be a Date object or a stored date string, in UTC in both cases.
     * if it's falsy ("", null, undefined), we reset the control
     */
    setUTCDate(date) {
        if (!date) {
            this.setValue(null);
            return;
        }

        if (typeof date === 'string' || date instanceof String) {
            const trimmedDate = Ext.util.Format.trim(date);

            if (trimmedDate === '' || trimmedDate === '0000-00-00 00:00:00') {
                // empty string
                this.setValue(null);
                return;
            }

            date = Utils.parseDate(trimmedDate);
        }

        // at this point date is surely a Date object

        const localDate = date.convertToLocalCopy();
        this.setValue(localDate);
    },

    getFilters() {
        const filters = [];
        const property = this.fieldName ? this.fieldName : this.name;
        const { adjustedValue, adjustedOperator: operator } = this.getPeriodAdjustedFilter();
        if (adjustedValue) {
            const value = Ext.Date.format(adjustedValue, 'Y-m-d H:i:s');
            const fieldConfigs = this.getFieldConfigs();
            const filter = {
                value, operator, property, isDisabled: this.getDisabled(),
            };
            if (this.getTableName()) {
                filter.tableName = this.getTableName();
            }
            if (fieldConfigs) {
                fieldConfigs.forEach(config => {
                    filters.push({ ...filter, tableName: config.tableName, property: config.property });
                });
            } else {
                filters.push(filter);
            }
        }
        return filters;
    },

    getPeriodAdjustedFilter() {
        const operator = this.filterOperator || this.getConfig('filterOperator');
        const value = this.getValue();
        if (!value) {
            return {
                adjustedValue: null,
                adjustedOperator: null,
            };
        }
        const format = this.getDateField().getDateFormat();
        if (!format.match(/d/i)) {
            value.setDate(1);
        }
        if (!this.getViewModel().get('mode').match(/time/i)) {
            value.setHours(0, 0, 0, 0);
        }
        const adjustedOperator = ['<=', '>='].includes(operator) ? operator.replace('=', '') : operator;
            
        if (format.match(/s/i) || format.match(/i/i) || !['<=', '>'].includes(operator)) {
            return {
                adjustedValue: value,
                adjustedOperator,
            };
        }
        let correctionPeriod = Ext.Date.YEAR;
        if (format.match(/m/i)) {
            correctionPeriod = Ext.Date.MONTH;
        }
        if (format.match(/d/i)) {
            correctionPeriod = Ext.Date.DAY;
        }
        if (format.match(/h/i)) {
            correctionPeriod = Ext.Date.HOUR;
        }
        return {
            adjustedValue: Ext.Date.add(value, correctionPeriod, 1),
            adjustedOperator,
        };
    },

    isXType(xtype, shallow) {
        if (xtype === 'field') {
            return this.getIsField();
        }
        return this.callParent([xtype, shallow]);
    },

});
