import controller from './QueryCheckoutController';

export default Ext.define('FieldServices.view.company.tools.sumup.QueryCheckoutView', {
    extend: 'Ext.panel.Panel',
    xtype: 'queryCheckoutView',
    title: 'Query checkout',
    tools: [{
        type: 'close',
        handler: 'onCancelButtonTap',
    }],
    controller,
    layout: {
        type: 'vbox',
        align: 'stretch',
    },
    items: [
        {
            xtype: 'textfield',
            name: 'transactionId',
            label: 'Checkout ID',
            labelAlign: 'left',
            labelWidth: 200,
            itemId: 'checkoutId',
        }, {
            xtype: 'button',
            text: 'Query Checkout',
            handler: 'onTapQueryCheckout',
        }, {
            xtype: 'container',
            flex: 1,
            layout: 'hbox',
            items: [{
                xtype: 'textareafield',
                readonly: true,
                itemId: 'queryResult',
                flex: 1,
            }, {
                xtype: 'button',
                text: 'Create missing payments',
                itemId: 'createMissingPayment',
                handler: 'onTapCreateMissingPayment',
                disabled: true,
            }],
        },

    ],
});
