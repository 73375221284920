import './edit/JobEditView';

Ext.define('FieldServices.view.company.job.JobListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.jobListController',
    xtype: 'jobListController',

    control: {
        '': {
            itemtap: 'onItemTap',
        },
    },
    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this.reloadData, this);
    },

    reloadData() {
        this.getView().getPlugin('jobFilterPlugin').reloadData();
    },

    onItemTap(dataview, index, target, record, e) {
        References.getJobPopup().show();
        References.getJobPopup().getViewModel().setData(Ext.clone(record.data));
        References.getJobPopup().on('dataChanged', (scope, records, succesful, operation) => {
            this.reloadData()
        }, this);
    },
    onRestoreButtonTap(elem) {
        this._restoreProject(elem)
            .then(() => this.reloadData())
            .catch(handleClientError);
    },

    async _restoreProject(elem) {
        const record = elem.ownerCmp.getRecord();
        if (!record.data.isdeleted) {
            throw new Error(`Cannot restore project with id: ${record.data.id}. Project is not deleted.`);
        }
        this.getView().setMasked({ xtype: 'loadmask', message: 'Restoring...' });

        record.set({ isdeleted: false });
        await FieldServices.app.callAPIEndpointRequest('PUT', `api/admindata/company-tables/Job/${record.data.company_id}`, record.data)
            .catch(err => {
                handleClientError(err);
            })
            .finally(() => this.getView().setMasked(false));
    },

});
