import './sumup/QueryTransactionView';
import './sumup/QueryCheckoutView';
import './messageLimitStats/MessageLimitStatsView';

Ext.define('FieldServices.view.company.tools.CompanyToolsController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.CompanyToolsController',
    xtype: 'companyToolsController',

    mixins: ['FieldServices.mixins.subPage'],
    control: {
        queryTransaction: {
            tap: 'onTapQueryTransaction',
        },
        queryCheckout: {
            tap: 'onTapQueryCheckout',
        },
        messageLimitButton: {
          tap: 'onMessageLimitButtonTap'
        }
    },

    featureRecord: null,
    companyId: null,

    init() {
        this.callParent();
    },

    onTapQueryTransaction() {
        References.getSumupQueryTransactionPopup().show();
    },
    onTapQueryCheckout() {
        References.getSumupQueryCheckoutPopup().show();
    },

    onMessageLimitButtonTap() {
       References.getMessageLimitStatsPopup().show();
    },
});
