require('./SalesReportFilterView.scss');

Ext.define('FieldServices.view.report.salesReport.filter.SalesReportFilterView', {
    extend: 'Ext.form.Panel',

    requires: [
        'FieldServices.view.report.salesReport.filter.SalesReportFilterViewController',
        'FieldServices.view.widgets.filters.DateRangeFilter',
        'FieldServices.view.widgets.filters.WhiteLabelFilter',
    ],

    xtype: 'salesReportFilterView',

    controller: 'salesReportFilterViewController',

    scrollable: true,

    layout: 'hbox',
    listeners: {
        initialize: 'loadFilterOptions',
    },
    viewModel: {
        data: {
            businessTypes: [],
            teamSizes: [],
            currencies: [],
            maxUserCount: Infinity,
        },
        formulas: {
            businessTypeOptions: {
                bind: '{businessTypes}',
                get(businessTypes) {
                    return this.generateSelectOptionsFromValues(businessTypes);
                },
            },
            currencyOptions: {
                bind: '{currencies}',
                get(currencies) {
                    return this.generateSelectOptionsFromValues(currencies);
                },
            },
            teamSizeOptions: {
                bind: '{teamSizes}',
                get(teamSizes) {
                    return this.generateSelectOptionsFromValues(teamSizes);
                },
            },
        },
        generateSelectOptionsFromValues(values) {
            return [
                { text: 'Any', value: '' },
                ...values.map(item => ({ text: item, value: item })),
            ];
        },
    },

    constructor(config) {
        config.items = this.getItemsConfig();
        this.callParent(arguments);
    },

    getDisplayValue() {
        return this.getFilters().length ? '_filtered' : '';
    },

    getItemsConfig() {
        const maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() - 1);
        return [
            {
                xtype: 'container',
                layout: 'vbox',
                flex: 1,
                items: [
                    {
                        xtype: 'container',
                        layout: 'hbox',
                        flex: 1,
                        items: [
                            {
                                xtype: 'container',
                                layout: 'vbox',
                                flex: 1,
                                items: [{
                                    xtype: 'dateRangeFilter',
                                    itemId: 'dateRangeFilter',
                                    title: 'Report Period:',
                                    name: 'year_month',
                                    tableName: 'UsageHistory',
                                    maxDate,
                                    mode: 'date',
                                    dateFormat: 'Y-m',
                                    inclusive: true,
                                    layout: 'hbox',
                                },
                                ],
                            },
                            {
                                xtype: 'container',
                                layout: 'vbox',
                                cls: 'salesReport',
                                flex: 1,
                                items: [
                                    {
                                        xtype: 'whiteLabelFilter',
                                        includeTest: false,
                                        itemId: 'whiteLabelSelect',
                                    },

                                ],
                            },
                        ],
                    },
                    {
                        xtype: 'formpanel',
                        layout: 'hbox',
                        defaults: {
                            labelAlign: 'top',
                            labelWidth: 'auto',
                            flex: 1,
                            tableName: 'UsageHistory',
                            isFilter: true,
                        },
                        items: [
                            {
                                xtype: 'selectfield',
                                itemId: 'businessTypeFilterField',
                                label: 'Business Type',
                                name: 'business_type',
                                filterOperator: 'like',
                                bind: {
                                    options: '{businessTypeOptions}',
                                },
                            },
                            {
                                xtype: 'selectfield',
                                itemId: 'currencyFilterField',
                                label: 'Currency',
                                name: 'currency',
                                filterOperator: 'like',
                                bind: {
                                    options: '{currencyOptions}',
                                },
                            },
                            {
                                xtype: 'selectfield',
                                itemId: 'minTeamSizeField',
                                label: 'Minimum Team Size',
                                fieldName: 'team_size',
                                name: 'team_size_min',
                                filterOperator: '>=',
                                bind: {
                                    options: '{teamSizeOptions}',
                                },
                            },
                            {
                                xtype: 'selectfield',
                                itemId: 'maxTeamSizeField',
                                label: 'Maximum Team Size',
                                fieldName: 'team_size',
                                name: 'team_size_max',
                                filterOperator: '<=',
                                bind: {
                                    options: '{teamSizeOptions}',
                                },
                            },
                            {
                                xtype: 'spinnerfield',
                                itemId: 'minUserCountField',
                                label: 'Minimum Number of Users',
                                fieldName: 'users',
                                name: 'users_min',
                                filterOperator: '>=',
                                minValue: 0,
                                bind: {
                                    maxValue: '{maxUserCount}',
                                },
                            },
                            {
                                xtype: 'spinnerfield',
                                itemId: 'maxUserCountField',
                                label: 'Maximum Number of Users',
                                fieldName: 'users',
                                name: 'users_max',
                                filterOperator: '<=',
                                minValue: 0,
                                maxValue: Infinity,
                                bind: {
                                    maxValue: '{maxUserCount}',
                                },
                            },
                        ],
                    },

                ],
            },

            {
                xtype: 'container',
                docked: 'bottom',
                layout: 'hbox',
                cls: 'reportViewButtonsLine',
                items: [{
                    xtype: 'button',
                    text: 'Load',
                    cls: 'btn',
                    handler: 'onChangeFilter',
                },
                {
                    xtype: 'checkboxfield',
                    labelWidth: 'auto',
                    itemId: 'includeChurnedCheckbox',
                    label: 'Include Churned Companies',
                },
                {
                    xtype: 'button',
                    text: 'Export as xls',
                    iconCls: 'ion-document',
                    handler: 'onDownloadCsvFile',
                    right: '0',
                }],
            },
        ];
    },

});
