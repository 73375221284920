import {FREE_SUBSCRIPTION_SETUPS} from '@powerednow/shared/constants/subscription';

Ext.define('FieldServices.view.report.monthlyReports.detailed.MonthlyReportsDetailedViewController', {
    extend: 'Ext.app.ViewController',
    alias: 'controller.monthlyReportsDetailedViewController',
    xtype: 'monthlyReportsDetailedViewController',

    control: {
        monthlyReportsFilter: {
            changeFilter: 'loadMonthlyData',
            exportRequested: 'onExportRequested',
            promoExcludeChanged: 'setPromotionalSubscriptionFilter',
        },
        gridTabs: true,
    },

    config: {
        companyId: null,
    },

    onExportRequested() {
        const activeTab = this.getGridTabs().getActiveItem();
        const reportName = activeTab.tab.config.title.replace(' ', '_');
        const reportDate = this.getMonthlyReportsFilter().getDisplayValue();
        activeTab.saveDocumentAs({ fileName: `${reportName} ${reportDate}.xlsx` });
    },

    async loadMonthlyData({ filterData }) {
        this.getViewModel().setData({ monthlyDataLoading: true });
        try {
            await this.loadSubscriptionsOfMonth(filterData);
            await this.loadActiveSubsForMonth(filterData);
            await this.loadNewRegistrations(filterData);

            this.getViewModel().setData({ monthlyDataLoading: false });
            this.showReportSourceDetails();
        } catch (error) {
            this.getViewModel().setData({ monthlyDataLoading: false });
            handleClientError(error);
        }
    },

    async loadNewRegistrations(filterData) {
      const apiResponse = await FieldServices.app.callAPI(
        {
          url: `api/admindata/purchases/getNewRegistrationsForMonth/0`,
          method: 'GET',
          params: { filter: Ext.encode(filterData) },
          headers: {},
        },
      );

      const data = apiResponse || [];
      Ext.getStore('NewRegistrationsInMonth').setData(data);
    },

     async loadSubscriptionsOfMonth(filterData) {
       const apiResponse = await FieldServices.app.callAPI(
         {
           url: `api/admindata/purchases/getSubscriptionsForMonth/0`,
           method: 'GET',
           params: { filter: Ext.encode(filterData) },
           headers: {},
         },
       );

       this.getViewModel().setData({rawPurchasesOfMonth: apiResponse || []});
       this.loadSubscriptionStores();
     },

    async loadActiveSubsForMonth(filterData) {
      const baseFilterData = [];
      const comparisonFilterData = [];
      filterData.forEach(filterObj => {
        switch(filterObj.property){
          case 'month':
            baseFilterData.push(filterObj);
            break;
          case 'comparisonMonth':
            comparisonFilterData.push(filterObj);
            break;
          default:
            baseFilterData.push(filterObj);
            comparisonFilterData.push(filterObj);
        }
      });
      const apiResponse = await FieldServices.app.callAPI(
        {
          url: `api/admindata/purchases/getSubscribersForMonth/0`,
          method: 'GET',
          params: { filter: Ext.encode(baseFilterData) },
          headers: {},
        },
      );
      const comparisonResponse = await FieldServices.app.callAPI(
        {
          url: `api/admindata/purchases/getSubscribersForMonth/0`,
          method: 'GET',
          params: { filter: Ext.encode(comparisonFilterData) },
          headers: {},
        },
      );

      this.getViewModel().setData({rawActiveSubsOfMonth: apiResponse || [], rawActiveSubsOfComparisonMonth: comparisonResponse || []});
      this.loadActiveSubsStore();
    },

    loadSubscriptionStores() {
      const { rawPurchasesOfMonth } = this.getViewModel().getData();
      const monthlyPurchaseData = [];
      const newPurchaseData = [];
      const monthlyPurchaseStats = { subRecords: {paid : 0, promo: 0}, consumptionRecords: {paid: 0, promo: 0}, companies: 0 };
      const newPurchaseStats = { subRecords: {paid : 0, promo: 0}, consumptionRecords: {paid: 0, promo: 0}, companies: 0 };
      const { promoOriginIds, excludePromotionalSubs } = this.getPromoFilteringData();
      Object.values(rawPurchasesOfMonth).forEach(({subscriptions, consumptions}) => {
        monthlyPurchaseStats.companies += 1;
        [...subscriptions, ...consumptions].forEach(purchaseRec => {
          const isPromo = promoOriginIds.includes(purchaseRec.purchaseOrigin);
          const recordCategoryKey = purchaseRec.PurchaseAction ? 'subRecords' : 'consumptionRecords';
          const countPropKey = isPromo ? 'promo' : 'paid';
          monthlyPurchaseStats[recordCategoryKey][countPropKey] += 1;
          if(!isPromo || !excludePromotionalSubs){
            monthlyPurchaseData.push(purchaseRec);
          }
        })
        const newSub = subscriptions
          .filter(subRecord => !promoOriginIds.includes(subRecord.purchaseOrigin) || !excludePromotionalSubs)
          .find(subRecord => subRecord.PurchaseAction.action === 0);
        if(newSub){
          newPurchaseStats.companies += 1;
          newPurchaseStats.subRecords[promoOriginIds.includes(newSub.purchaseOrigin) ? 'promo' : 'paid'] += 1;
          newSub.quantity = 1;
          consumptions.forEach(consumptionRec => {
            const isPromo = promoOriginIds.includes(consumptionRec.purchaseOrigin);
            const countPropKey = isPromo ? 'promo' : 'paid';
            newPurchaseStats.consumptionRecords[countPropKey] += 1;
            if(!isPromo || !excludePromotionalSubs){
              newSub.quantity += 1;
              newSub.purchaseAmount += consumptionRec.purchaseAmount;
              newSub.originalPrice += consumptionRec.originalPrice;
            }
          });
          newPurchaseData.push(newSub);
        }
      });
      Ext.getStore('MonthlyPurchaseReportData').setData(monthlyPurchaseData);
      Ext.getStore('NewMonthlyReportedPurchases').setData(newPurchaseData);
      this.getViewModel().setData({monthlyPurchaseStats,newPurchaseStats});
    },

    loadActiveSubsStore(){
      const { rawActiveSubsOfMonth, rawActiveSubsOfComparisonMonth } = this.getViewModel().getData();
      const {activeSubsData, activeSubStats} = this.processActiveSubData(rawActiveSubsOfMonth);
      const {activeSubsData: comparisonSubsData} = this.processActiveSubData(rawActiveSubsOfComparisonMonth);
      const comparisonMap = {};
      comparisonSubsData.forEach(data=> {
        comparisonMap[data.company_id] = {values1: data}
      })
      activeSubsData.forEach(data=> {
        comparisonMap[data.company_id] = {...(comparisonMap[data.company_id] || {}), values2: data}
      })
      Ext.getStore('ActiveSubsAtMonthEnd').setData(activeSubsData);
      Ext.getStore('ActiveSubsAtMonthEndCompare').setData(Object.values(comparisonMap));
      this.getViewModel().setData({activeSubStats});
    },

    processActiveSubData(rawData){
      const activeSubsData = [];
      const activeSubStats = { subRecords: {paid : 0, promo: 0}, consumptionRecords: {paid: 0, promo: 0}, companies: 0 };
      const { promoOriginIds, excludePromotionalSubs } = this.getPromoFilteringData();
      Object.values(rawData).forEach(({subscriptions, consumptions}) => {
        if(subscriptions.length){
          activeSubStats.companies += 1;
          let sumPurchaseAmount = 0;
          let sumOriginalPrice = 0;
          let quantity = 0;
          [...subscriptions, ...consumptions].forEach(purchaseRec => {
            const isPromo = promoOriginIds.includes(purchaseRec.purchaseOrigin);
            const recordCategoryKey = purchaseRec.PurchaseAction ? 'subRecords' : 'consumptionRecords';
            const countPropKey = isPromo ? 'promo' : 'paid';
            activeSubStats[recordCategoryKey][countPropKey] += 1;
            if(!isPromo || !excludePromotionalSubs) {
              const { purchaseAmount, originalPrice } = purchaseRec;
              sumPurchaseAmount += purchaseAmount;
              sumOriginalPrice += originalPrice;
              quantity += 1;
            }
          });
          const usedSubRecords = subscriptions
            .filter(subRecord => !promoOriginIds.includes(subRecord.purchaseOrigin) || !excludePromotionalSubs);
          if(usedSubRecords.length){
            const activeSubRecord = usedSubRecords[0];
            const creationDate = usedSubRecords.sort((subRecordA, subRecordB) => subRecordA.creationDate - subRecordB.creationDate)[0].creationDate;
            activeSubsData.push({
              quantity,
              purchaseAmount: sumPurchaseAmount,
              originalPrice: sumOriginalPrice,
              creationDate,
              ...activeSubRecord
            });
          }
        }

      });
      return {activeSubStats, activeSubsData}
    },

    getPromoFilteringData(){
      const promoOriginIds = [4];
      Object.values(FREE_SUBSCRIPTION_SETUPS).forEach(subscriptionConfig => {
        if(!subscriptionConfig.INCLUDE_IN_REPORTS){
          promoOriginIds.push(subscriptionConfig.PURCHASE_ORIGIN_ID)
        }
      });
      const { excludePromotionalSubs } = this.getViewModel().getData();
      return {promoOriginIds, excludePromotionalSubs};
    },

    setPromotionalSubscriptionFilter(excludePromo) {
        this.getViewModel().setData({ monthlyDataLoading: true });
        this.getViewModel().setData({ excludePromotionalSubs: excludePromo });
        this.loadSubscriptionStores();
        this.loadActiveSubsStore();
        this.getViewModel().setData({ monthlyDataLoading: false });
    },

    showReportSourceDetails() {
      const {monthlyPurchaseStats, newPurchaseStats, activeSubStats} = this.getViewModel().getData();

      const dialog = Ext.create({
        xtype: 'dialog',
        title: 'Report Data Usage Details',

        html: `
          <table>
            <tr>
                <td>${this.generateReportStatHtml('All Subscriptions', monthlyPurchaseStats)}</td>
                <td>${this.generateReportStatHtml('New Subscribers', newPurchaseStats)}</td>
                <td>${this.generateReportStatHtml('Active Subscribers at the end of month',activeSubStats)}</td>
            </tr>
          </table>
          <ul>
            <li><small>(Promotional records are purchases with promotional purchase origins, the 'Total' values include these)</small></li>
            <li><small>(In the active subscriptions at the end of month report, companies that don't have an active base subscription are excluded, even if they have active extra users)</small></li>
          </ul>

        `,

        buttons: {
          ok: function () {
            dialog.destroy();
          }
        }
      });

      dialog.show();
    },

    generateReportStatHtml(title, dataObj) {
      const {subRecords, consumptionRecords, companies} = dataObj;
      const allSubs = subRecords.paid + subRecords.promo;
      const allConsumptions = consumptionRecords.paid + consumptionRecords.promo;
      return `
        <h3>${title}:</h3>
        <table>
            <tr><td>Records in range:</td><td><small>(All Subscriptions and Extra User purchases)</small></td></tr>
            <tr><td><ul><li>Total: ${allSubs + allConsumptions}</li><li>Promotional: ${subRecords.promo + consumptionRecords.promo}</li></ul></td></tr>
            <tr><td>Base Subscriptions reported</td><td><small>(Number of Subscription records)</small></td></tr>
            <tr><td><ul><li>Total: ${allSubs}</li><li>Promotional: ${subRecords.promo}</li></ul></td></tr>
            <tr><td>Extra Subscriptions reported</td><td><small>(Number of Extra User purchase records)</small></td></tr>
            <tr><td><ul><li>Total: ${allConsumptions}</li><li>Promotional: ${consumptionRecords.promo}</li></ul></td></tr>
            <tr><td>Total Companies reported on</td><td>${companies} <small>(Number of distinct companies with records in range)</small></td></tr>
        </table>
      `
    }

});
