import controller from './QueryTransactionController';

export default Ext.define('FieldServices.view.company.tools.sumup.QueryTransactionView', {
    extend: 'Ext.panel.Panel',
    xtype: 'queryTransactionView',
    title: 'Query transaction',
    tools: [{
        type: 'close',
        handler: 'onCancelButtonTap',
    }],
    controller,
    layout: {
        type: 'vbox',
        align: 'stretch',
    },
    items: [
        {
            xtype: 'textfield',
            name: 'transactionId',
            label: 'Transaction ID',
            labelAlign: 'left',
            labelWidth: 200,
            itemId: 'transactionId',
        }, {
            xtype: 'button',
            text: 'Query Transaction',
            handler: 'onQueryTransaction',
        }, {
            xtype: 'container',
            flex: 1,
            layout: 'hbox',
            items: [{
                xtype: 'textareafield',
                readonly: true,
                itemId: 'queryResult',
                flex: 1,
            }, {
                xtype: 'image',
                src: '',
                height: 'auto',
                width: '400px',
                itemId: 'receiptImage',
                style: {
                    'background-size': '100% 100%',
                },
            }],
        },

    ],
});
