export default Ext.define('FieldServices.view.company.document.edit.tabs.PaymentsController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.documentEditPaymentsController',

    async handleDeleteAction() {
        const selectedRecords = this.getView().getSelected()?.items;
        if (selectedRecords?.length > 0) {
            Ext.Msg.confirm('Confirm', `You are going to delete ${selectedRecords.length} payments. Are you sure?`, btn => {
                if (btn === 'yes') {
                    selectedRecords.forEach(record => record.set('isdeleted', 1));
                    selectedRecords[0].store.sync();
                }
            });
        }
    },

    handleUndeleteAction() {
        const selectedRecords = this.getView().getSelected()?.items;
        if (selectedRecords) {
            Ext.Msg.confirm('Confirm', `You are going to undelete ${selectedRecords.length} payments. Are you sure?`, btn => {
                if (btn === 'yes') {
                    selectedRecords.forEach(record => record.set('isdeleted', 0));
                    selectedRecords[0].store.sync();
                }
            });
        }
    },
});
