export default Ext.define('FieldServices.view.company.info.subscription.SubscriptionInfoItemModel', {
    extend: 'FieldServices.view.ListItemBaseViewModel',

    alias: 'viewmodel.subscriptionInfoItemModel',
    xtype: 'subscriptionInfoItemModel',

    config: {
        uiMap: {
            isActive: {
                ui: '-IsActive',
                condition(dataItem) {
                    if (dataItem.isActive) {
                        const activeRecords = this._getItems()
                            .filter(item => item.data.isActive);
                        if (activeRecords.length === 0) { //This is to prevent reduce on empty lists with no subscriptions
                            return false;
                        }
                        const heaviestSub = activeRecords.reduce((heaviest, current) => (heaviest.data.weight > current.data.weight ? heaviest : current));
                        return dataItem.weight === heaviestSub.data.weight;
                    }
                    return false;
                },
            },
        },
    },
    _getItems() {
        return Ext.getStore('SubscriptionInfoStore').data.items;
    },
});
