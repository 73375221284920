const Bluebird = require('bluebird');

Ext.define('FieldServices.view.company.document.list.DocumentListViewController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.documentListController',
    xtype: 'documentListController',

    control: {
        '': {
            itemtap: 'onItemTap',
            painted: 'onViewShow',
        },
        removeXeroLink: {
            click: 'onRemoveXeroLink',
        },
        unlockActionButton: {
            click: 'onUnlockDocuments',
        },
        lockActionButton: {
            click: 'onUnlockDocuments',
        },
        exportToXls: {
            click: 'onExportToXls',
        },
    },
    config: {
        companyId: null,
    },
    customersMap: {},

    init() {
        this.callParent();
        this.getViewModel().bind('{companyId}', this._reLoadDocumentStore, this);
    },

    onViewShow() {
        const companyId = this.getViewModel().get('companyId');
        if (companyId) {
            this.setCompanyId(companyId);
        }
    },

    onExportToXls() {
        this.getView().saveDocumentAs({ fileName: `Documents_${this.getCompanyId()}.xlsx` });
    },

    onUnlockDocuments(elem) {
        const documents = this._getSelectedDocuments();
        this._setLockStatus(documents, elem.status)
            .catch(handleClientError);
    },

    onRemoveXeroLink(elem) {
        const documents = this._getSelectedDocuments();
        this._removeIntegration(documents)
            .catch(handleClientError);
    },

    onItemTap(dataview, index, target, record, e) {
        if (this._isCheckBox(e)) {
            return;
        }
        References.getDocumentPopup().show();
        References.getDocumentPopup().getViewModel().setData(Ext.clone(record.data));
        References.getDocumentPopup().on('dataChanged', (scope, records, succesful, operation) => {
            this._reLoadDocumentStore()
                .catch(handleClientError);
        }, this);
    },

    rendererCustomerName(value, record) {
        return `${record.data.CustomerContactFirstName} ${record.data.CustomerContactLastName} - ${record.data.CustomerCompany}`;
    },

    rendererIsConfirmed(value, record, title, cell) {
        cell.toggleClsOnRow('grid_row_active', value === false || value === 0 || value === 'false');
        return value;
    },

    rendererIsDeleted(value, record, title, cell) {
        cell.toggleClsOnRow('grid_row_isDeleted', value === true || value === 1 || value === 'true');
        return value;
    },

    _getSelectedDocuments() {
        const grid = this.getView();
        const selectedList = grid.getStore().getData().items.filter(item => item.data.isCheck);
        return selectedList.map(record => record.data);
    },

    async _setLockStatus(documents, status) {
        if (documents) {
            await this._setLockStatusInDocuments(documents, status);
            Ext.Msg.alert('Alert', `The ${status === 1 ? 'Lock' : 'Unlock'} is done. (documents: ${documents.length})`);
            await this._reLoadDocumentStore();
        }
    },

    async _removeIntegration(documents) {
        if (documents) {
            await Bluebird.map(documents, async document => {
                const jsonData = {
                    id: document.id,
                    synced_uuid: null,
                    sync_uuid: null,
                    accounting_reference: null,
                    integration_failure: null,
                };

                return FieldServices.app.updateCompanyTable('Document', this.getCompanyId(), {
                    jsonData,
                });
            });

            Ext.Msg.alert('Alert', `Xero links removed for ${documents.length} document(s)`);
            await this._reLoadDocumentStore();
        }
    },

    _isCheckBox(e) {
        return e && ((e.to && e.to.column && e.to.column.xtype === 'checkcolumn') || e.target.className.includes('x-checkbox'));
    },

    _getDocumentStore() {
        return this.getView().getStore();
    },

    getGridFilterPlugin() {
        return this.getView().getPlugin('documentFilterPlugin');
    },

    async _reLoadDocumentStore() {
        await this.getGridFilterPlugin().reloadData();
    },

    async _setLockStatusInDocuments(documents, value = 0) {
        this.showLoader(this.getView());
        const list = [];
        documents.forEach(document => list.push(this._getLockStatusData(document, 'Document')));

        const payments = await this._getPaymentsByDocuments(documents);
        payments.forEach(payment => list.push(this._getLockStatusData(payment, 'Payment')));

        return Bluebird.map(list, item => this._sendLockStatusToApi(item, value), { concurrency: 3 })
            .catch(err => {
                handleClientError(err);
            })
            .finally(() => {
                this.clearLoader(this.getView());
            });
    },

    _getLockStatusData(data, tableName = 'Document') {
        return {
            id: data.id,
            tableName,
            data,
        };
    },

    async _getPaymentsByDocuments(documents) {
        const paymentToInvoiceStore = Ext.create('Ext.data.ChainedStore', { source: Ext.getStore('PaymentToInvoice') });

        paymentToInvoiceStore.clearFilter();
        paymentToInvoiceStore.filter([{
            property: 'company_id',
            value: this.getCompanyId(),
            operator: '=',
        }, {
            property: 'isdeleted',
            operator: 'in',
            value: [true, false],
        }, {
            property: 'document_id',
            operator: 'in',
            value: documents.map(document => document.id),
        }]);

        const paymentToInvoices = paymentToInvoiceStore.getRecords();

        return paymentToInvoices.map(paymentToInvoice => Ext.getStore('Payment').data.byPaymentId.get(paymentToInvoice.data.payment_id).data);
    },

    _sendLockStatusToApi({ tableName, id, data }, value = 0) {
        const jsonData = {
            id,
            islocked: parseInt(value, 10),
            isdeleted: data.isdeleted,
        };

        return FieldServices.app.updateCompanyTable(tableName, this.getCompanyId(), {
            jsonData,
        });
    },
});
