import PaymentController from './PaymentsController';

export default Ext.define('FieldServices.view.company.document.edit.DocumentEditView.tabs.Payments', {
    extend: 'Ext.grid.Grid',
    ui: '-documents',
    loadStoreWhenVisible: true,
    controller: PaymentController,
    alias: 'widget.DocumentEditPaymentTab',
    requires: ['Ext.grid.column.Selection', 'Ext.grid.plugin.RowOperations'],
    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridexporter',
    }, {
        type: 'rowoperations',
        operation: {
            text: 'Operations',
            ui: 'alt',

            menu: [{
                text: 'Undelete',
                iconCls: 'x-fa fa-refresh',
                handler: 'handleUndeleteAction',
            }, {
                text: 'Delete',
                iconCls: 'x-fa fa-trash',
                handler: 'handleDeleteAction',
            }],
        },
    }],
    itemConfig: {
        viewModel: {},
    },

    emptyText: 'empty',
    bind: {
        store: '{paymentsStore}',
    },
    columns: [
        {
            text: 'Id',
            dataIndex: 'id',
            minWidth: '120',
        }, {
            text: 'document id',
            dataIndex: 'DocumentId',
        }, {
            text: 'Document display number',
            dataIndex: 'DocumentDisplayNumber',
        }, {
            text: 'Document isLocked',
            dataIndex: 'DocumentIsLocked',
        }, {
            text: 'Payment type',
            dataIndex: 'PaymentTypeName',
        }, {
            text: 'dt_created',
            dataIndex: 'dt_created',
        }, {
            text: 'amount',
            dataIndex: 'amount',
        }, {
            text: 'is locked',
            dataIndex: 'islocked',
        }, {
            text: 'locked date',
            dataIndex: 'dt_locked',
        }, {
            text: 'is archived',
            dataIndex: 'is_archived',
        }, {
            text: 'is deleted',
            dataIndex: 'isdeleted',
            width: 50,
            renderer: 'rendererIsDeleted',
        },
    ],
});
