export default Ext.define('FieldServices.view.company.document.edit.tabs.DocumentItemsController', {
    extend: 'FieldServices.view.BaseViewController',

    alias: 'controller.documentEditDocumentItemsController',

    handleAction(actionName,actionPropName,actionPropValue){
      const selectedRecords = this.getView().getSelected()?.items;
      if (selectedRecords?.length > 0) {
        Ext.Msg.confirm('Confirm', `You are going to ${actionName} ${selectedRecords.length} document item(s). Are you sure?`, btn => {
          if (btn === 'yes') {
            selectedRecords.forEach(record => record.set(actionPropName, actionPropValue));
            selectedRecords[0].store.sync();
          }
        });
      }
    },

    handleDeleteAction() {
        this.handleAction('delete', 'isdeleted', 1);
    },

    handleUndeleteAction() {
        this.handleAction('undelete','isdeleted',0);
    },

    handleArchiveAction(){
      this.handleAction('archive', 'is_archived',1);
    },

    handleUnarchiveAction(){
      this.handleAction('unarchive', 'is_archived',0);
    },
});
