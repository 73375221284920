import './tabs/General';
import './tabs/Payments';
import './tabs/DocumentItems';

Ext.define('FieldServices.view.company.document.edit.DocumentEditView', {
    extend: 'Ext.Panel',

    requires: [
        'FieldServices.view.company.document.edit.DocumentEditViewController',
        'FieldServices.view.company.document.edit.DocumentEditViewModel',
    ],
    layout: 'fit',

    xtype: 'documentEditView',

    controller: 'documentEditViewController',

    store: 'Document',

    viewModel: 'documentEditViewModel',

    listeners: {
        painted: 'onLoad',
    },
    nameHolder: true,
    scrollable: true,

    title: 'Edit Document',

    tools: [{
        type: 'close',
        handler: 'onCancelButtonTap',
    }],

    cls: 'panel_padding',

    changeDocumentDataFields: ['id', 'displaynumber', 'documentnumber'],
    items: [{
        xtype: 'tabpanel',
        items: [{
            xtype: 'DocumentEditGeneralTab',
            title: 'General',
        }, {
          xtype: 'DocumentEditPaymentTab',
          title: 'Payments',
        }, {
          xtype: 'DocumentEditDocumentItemsTab',
          title: 'Document items',
        }],
    }],
});
