import 'FieldServices/proxy/PoweredNowApi';

Ext.define('FieldServices.store.PurchaseSetup', {
    extend: 'Ext.data.Store',
    requires: ['FieldServices.model.PurchaseSetup'],
    alias: 'store.PurchaseSetup',
    config: {
        autoLoad: false,
        model: 'FieldServices.model.PurchaseSetup',
        storeId: 'PurchaseSetup',
        filters: [{
          filterFn :(record) => ! record.data.dt_valid_to || new Date(record.data.dt_valid_to) >= new Date(),
        }]
    },
    storeId: 'PurchaseSetup',

    proxy: {
        type: 'PoweredNowApi',
        readInChunks: false,
        url: `${require('config.js').SERVER_URL}api/admindata/global/PurchaseSetup`,
    },
});
