import PricedItemsViewController from './PricedItemsViewController';

export default Ext.define('FieldServices.view.company.job.edit.tabs.JobItemGroup', {
    extend: 'Ext.grid.Grid',
    ui: '-documents',
    loadStoreWhenVisible: true,
    controller: PricedItemsViewController,
    alias: 'widget.JobEditPricedItemsTab',
    requires: ['Ext.grid.column.Selection', 'Ext.grid.plugin.RowOperations'],
    plugins: [{
        type: 'gridcolumnresizing',
    }, {
        type: 'gridexporter',
    }, {
        type: 'rowoperations',
        operation: {
            text: 'Operations',
            ui: 'alt',

            menu: [{
                text: 'Undelete',
                iconCls: 'x-fa fa-refresh',
                handler: 'handleUndeleteAction',
            }, {
              text: 'Delete',
              iconCls: 'x-fa fa-trash',
              handler: 'handleDeleteAction',
            },
              {
                text: 'Archive',
                iconCls: 'x-fa fa-archive',
                handler: 'handleArchiveAction',
              }, {
                text: 'Unarchive',
                iconCls: 'x-fa fa-folder-open',
                handler: 'handleUnarchiveAction',
              }],
        },
    }],
    itemConfig: {
        viewModel: {},
    },

    emptyText: 'empty',
    bind: {
        store: '{pricedItemStore}',
    },
    columns: [
        {
            text: 'Id',
            dataIndex: 'id',
            minWidth: '120',
        }, {
            text: 'Title',
            dataIndex: 'title',
        }, {
            text: 'Description',
            dataIndex: 'description',
        }, {
            text: 'Total',
            dataIndex: 'total',
        }, {
            text: 'Sub-total',
            dataIndex: 'subtotal',
        }, {
            text: 'Discount',
            dataIndex: 'discount',
        }, {
            text: 'VAT',
            dataIndex: 'vat',
        }, {
            text: 'Last activity',
            dataIndex: 'lastActivity',
        }, {
            text: 'dt_created',
            dataIndex: 'dt_created',
        }, {
            text: 'archived',
            dataIndex: 'is_archived',
        }, {
            text: 'deleted',
            dataIndex: 'isdeleted',
            width: 50,
            renderer: 'rendererIsDeleted',
        },
    ],
});
