export default Ext.define('FieldServices.view.company.job.edit.tabs.General', {
    extend: 'Ext.Container',
    alias: 'widget.JobEditGeneralTab',
    layout: 'vbox',
    defaultType: 'textfield',

    items: [
      {
        bind: {
          value: '{id}',
        },
        label: 'id',
        labelAlign: 'top',
        name: 'id',
        disabled: true,
      },
      {
        xtype: 'component',
        html: 'This is just a placeholder for project editing functionality, you cannot do anything on the project itself,<br/>' +
          'only with priceditems'
      },
        {
            xtype: 'toolbar',
            docked: 'bottom',
            items: ['->',
              {
                    xtype: 'button',
                    text: 'Cancel',
                    iconCls: 'ion-ios-close-outline',
                    handler: 'onCancelButtonTap',
                }],
        },
    ],
});
