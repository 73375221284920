Ext.define('FieldServices.view.company.job.edit.JobEditViewModel', {
    extend: 'Ext.app.ViewModel',
    alias: 'viewmodel.jobEditViewModel',
    stores: {
        pricedItemStore: {
            type: 'JobItemGroup',
            filters: [
                {
                    property: 'company_id',
                    value: '{companyId}',
                    operator: '=',
                }, {
                    property: 'isdeleted',
                    operator: 'in',
                    value: [true, false],
                }, {
                    property: 'job_id',
                    value: '{id}',
                }, {
                    property: 'is_hidden',
                    value: false,
                },
            ],
        },

    },
    formulas: {

    },
});
