import { utilities as Utilities } from '@powerednow/client-shared/Utils/utilities';
import GLOBAL_SHARED_CONSTANTS from 'shared/constants';
import CLIENT_SHARED_CONSTANTS from 'client/shared/constants';
import { retry, retryTimeout } from '@powerednow/shared/decorators';

export default Ext.define('Shared.mixins.ApiRequest', {
    extend: 'Ext.Mixin',

    @retryTimeout(attempt => attempt * 2000)
    @retry()
    _request(options) {
        return new Promise((resolve, reject) => {
            const request = {
                success(response) {
                    const responseObject = Ext.decode(response.responseText);
                    if (Number(response.status) === 200) {
                        if (responseObject.error_code) {
                            reject(generateClientError(CLIENT_SHARED_CONSTANTS.ERRORS.SERVER_ERROR, {
                                code: parseInt(responseObject.error_code, 10),
                                message: responseObject.error_msg,
                                response,
                                decodedResponse: responseObject,
                            }));
                        } else {
                            resolve(responseObject);
                        }
                    } else {
                        reject(generateClientError(CLIENT_SHARED_CONSTANTS.ERRORS.SERVER_STATUS, {
                            message: response.responseText,
                            response,
                        }));
                    }
                },
                failure(response) {
                    const statusCode = Number(response.status);
                    const isServerError = (statusCode >= 500 && statusCode <= 599);
                    if (!Utilities.isOnline() || !statusCode || isServerError) {
                        reject(generateClientError(CLIENT_SHARED_CONSTANTS.ERRORS.NETWORK_FAILURE, {
                            response,
                        }));
                    } else if (statusCode === 404) {
                        //
                        // empty results
                        //
                        resolve([]);
                    } else {
                        reject(generateClientError(CLIENT_SHARED_CONSTANTS.ERRORS.SERVER_STATUS, {
                            message: response.responseText,
                            response,
                        }));
                    }
                },
            };
            this._mergeRequestOptions(options, request);
            this._mergeRequestOptions(options, this._getAuthRequestOptions(options));

            Ext.Ajax.request(options);
        });
    },

    _mergeRequestOptions(request, authRequestOptions) {
        Object.keys(authRequestOptions).forEach(key => {
            //
            // do not override header, just append new values
            //
            if (key === 'headers') {
                request[key] = request[key] || {};
                Object.keys(authRequestOptions[key]).forEach(headerKey => {
                    request[key][headerKey] = authRequestOptions[key][headerKey];
                });
            } else {
                request[key] = authRequestOptions[key];
            }
        });
    },

    _getAuthRequestOptions(obj) {
        const request = {};
        // define headers
        request.headers = {};
        if (obj.auth) {
            request.headers = Ext.clone(AuthManager.getAuthHeader());
        }
        this._ensureAppVersion(request);

        if (obj.headers) {
            request.headers = Ext.apply({}, request.headers);
            request.headers = Ext.apply(request.headers, obj.headers);
            delete obj.headers;
        }

        if (obj.tempUrl) {
            request.headers['x-request-tmp-url'] = 'YES';
        }
        return request;
    },

    _ensureAppVersion(request) {
        if (typeof request.headers[GLOBAL_SHARED_CONSTANTS.HTTP.HEADER_APP_VERSION] === 'undefined') {
            Object.assign(request.headers, {
                [GLOBAL_SHARED_CONSTANTS.HTTP.HEADER_APP_VERSION]: Utilities.getVersion(),
                [GLOBAL_SHARED_CONSTANTS.HTTP.HEADER_DISPLAY_VERSION]: Utilities.getDisplayVersion(),
            });
        }
    },
});
